.rt-feature-box {
    border: 1px solid rgb(213, 213, 213);
    display: block;
    color: $body-font-colr;
    @include rt-trs(0.34s);
    .domian-bg-color {
        height: 111px;
        background: $primary-color;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 11px 0;
        color: #fff;
        &.color--1 {
            background: linear-gradient( -102deg, rgb(75,201,254) 0%, rgb(80,82,254) 100%); }

        &.color--2 {
            background: linear-gradient( -102deg, rgb(253,178,60) 0%, rgb(233,129,59) 100%); }

        &.color--3 {
            background: linear-gradient( -102deg, rgb(235,126,10) 0%, rgb(243,0,112) 100%); }

        &.color--4 {
            background: linear-gradient( -102deg, rgb(130,116,232) 0%, rgb(198,121,208) 100%); } }
    ul {
        border-top: 1px solid rgb(213, 213, 213);
        margin-top: 11px;
        padding-top: 11px; }
    &:hover {
        box-shadow: 0px 5px 15px 0px rgba(28, 75, 154, 0.1);
        color: $body-font-colr;
        transform: translateY(-10px); } }



.feature-active .rt-feature-box {
    margin-top: 20px;
    margin-bottom: 20px; }
