.services-box-1 {
    position: relative;
    background-color: #fff;
    border-color: rgb(42, 56, 76);
    border-radius: 20px;
    box-shadow: 0px 1px 10px 0px rgba(55, 94, 246, 0.1);
    @include rt-trs(0.24s);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 310px;
    justify-content: center;
    margin-bottom: 30px;
    padding: {
        left: 20px;
        right: 20px; }
    h4 {
        font-size: 24px;
        text-transform: capitalize;
        font-weight: 700;
        margin: 0 0  10px; }
    p {
        font-size: 14px;
        margin: 0 0  0;
        line-height: 26px; }
    .services-thumb {
        margin-bottom: 20px; }
    &:hover {
        box-shadow: 0px 4px 10px 0px rgba(55, 94, 246, 0.4);
        .services-thumb {
            img {
                animation: pulse 400ms ease-in-out; } } }
    &.dark-box {
        background-color: #252c48;
        border-color: rgba(42, 56, 76, 0.5);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        h4, p {
            color: #fff; }
        &:hover {
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4); } } }







// services page two
.services-box-2 {
    position: relative;
    margin-bottom: 30px;
    .inner-counter {
        font-size: 70px;
        color: rgba(#333, 0.20);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        &:before {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter, decimal-leading-zero) " "; } }
    .services-thumb {
        margin-bottom: 24px; }
    h4 {
        font-size: 24px;
        margin: 0 0 17px;
        font-weight: 700; }
    p {
        margin: 0 0 0;
        font-size: 14px {
         padding: {
            left: 20px;
            right: 20px; } } }
    &.dark-box {
        h4 {
            color: #fff; }
        .inner-counter {
            color: rgba(#4967fd, 0.15); }
        p {
            color: #c7d1fc; } }
    &:hover {
        .services-thumb {
             animation: rotate-in-2-fwd 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; } } }


