.rt-site-footer {
    position: relative;
    background-color: #f6f7fa;
    margin-top: 70px;
    .footer-top {
        padding-top: 100px;
        padding-bottom: 30px; }
    .footer-bottom {
        background-color: #f6f7fa;
        .container {
          border-top: 1px solid rgb(221, 221, 221);
          padding: 20px 0; } }

    &.dark-footer {
      background-color: #000;
      .footer-bottom {
        background-color: #000;
        border-top: 1px solid rgba(71, 72, 72, 0.42); } }
    &.has-callto-action {
      margin-top: 170px;
      .footer-top {
        padding-top: 0px; } } }



.rt-single-widget {
  margin-bottom: 40px;
  a {
    color: $primary-color; }
  .rt-footer-title {
    margin-bottom: 25px;
    font-size: 24px;
    text-transform: uppercase;
    color: $headnig-font-color;
    @include breakpoint(lg) {
      font-size: 22px; } }
  .btn {
    width: 50px;
    background-color: $primary-color;
    font-size: 20px;
    color: #fff; }
  .form-control {
    background: #e8e6ff;
    border-color: transparent;
    height: 52px;
    padding-left: 28px;
    &:focus {
      border-color: $primary-color;
      box-shadow: none;
      outline: none; }
    &::placeholder {
      color: $body-font-colr;
      font-weight: 300;
      font-size: 14px; } }
  ul.rt-usefulllinks {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      width: 48%;
      margin-bottom: 20px;
      position: relative;
      &:after {
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: $primary-color;
        content: '';
        transition: all 0.24s ease-in-out; }
      &:last-child {
        margin-bottom: 0px; }
      a {
        display: block;
        color: $body-font-colr;
        transition: all 0.24s ease-in-out;
        &:hover {
          color: $primary-color;
          padding-left: 5px; } }
      &:hover {
        &:after {
          background: $secondary-color;
          animation: zoomIn 0.24s ease-in-out; } } } }
  ul.rt-usefulllinks2 {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: block;
      padding-bottom: 15px;
      &:last-child {
        padding-bottom: 0px; }
      a {
        display: block;
        font-size: 24px;
        color: $body-font-colr;
        font-weight: 200;
        @include breakpoint(lg) {
          font-size: 18px; }
        i {
          margin-right: 4px; }
        @include rt-trs(0.34s);
        &:hover {
          color: $primary-color;
          padding-left: 8px; } } } } }



//footer social
.rt-footer-social {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            margin-right: 8px;
            &:last-child {
                margin-right: 0px; }
            a {
                display: block;
                font-size: 18px;
                color: $body-font-colr;
                @include rt-trs(0.24sç);
                &:hover {
                  color: $primary-color; } } } } }
.rt-site-footer.dark-footer {
  .rt-footer-title {
    color: #fff; }
  .ul.rt-usefulllinks li a,
  .ul.rt-usefulllinks2 li a {
    color: #808080;
    &:hover {
      color: $primary-color; } }
  .rt-single-widget {
    .form-control {
      border-color: transparent;
      background: #111; }
    .btn {
      background: #111;
      border-left: 1px solid rgba(71, 72, 72, 0.42); } } }
.copy-right-text {
  p {
    color: #536183;
    a {
      color: $primary-color; } } }
.footer-calltoaction {
  position: relative;
  top: -100px; }
