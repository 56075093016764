.rt-team {
    @include afterparent();
    .team-thumb {
        @include afterparent(); }
    .team-hover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include hidden();
        span.rt-alaert {
            color: #fbad3c; } }
    &.circle-team {
        .team-thumb {
            height: 264px;
            width: 264px;
            border-radius: 50%;
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(126, 93, 254, 0.90);
                content: "";
                border-radius: 50%;
                z-index: -1;
                @include hidden(); } } }
    &:hover {
        .team-hover {
            @include vis();
            h4 {
                animation: fadeInUp 400ms ease-in-out;
                transition-delay: 0.24s; }
            span {
                animation: fadeInDown 400ms ease-in-out;
                transition-delay: 0.24s; }
            p {
                animation: fadeInUp 500ms ease-in-out;
                transition-delay: 0.34s; } }
        &.circle-team {
            .team-thumb {
                &:after {
                    @include vis();
                    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; } } } } }

