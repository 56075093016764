// top header css
.top-header {
  background: transparent;
  .container {
     border-bottom: 1px solid  rgba(255, 255, 255, 0.20);
     padding: 10px 0; } }
.top-message {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    font-size: 14px;
    color: #333;
    i {
      color: #fff;
      font-size: 16px;
      padding-right: 3px; }
    a {
      display: block;
      color: #fff;
      i {
        color: #fff; } } } }
.top-social {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    padding-right: 10px;
    position: relative;
    &:last-child {
      padding-right: 0px; }
    a {
      display: block;
      text-align: center;
      color: #fff;
      font-size: 14px;
      &:hover {
        color: $secondary-color !important;
        i {
          color: $secondary-color !important; } }
      span img {

        margin-right: 5px; }
      i {
        padding-right: 5px; }
      @include rt-trs(0.24s); } } }


.top-social {
  .down-arrow.dropdown-toggle {
    position: relative;
    margin-right: 15px;
    &:after {
      border: none;
      content: "\eac8";
      font-family: "icofont";
      font-size: 18px;
      position: absolute;
      right: -18px;
      top: 50%;
      transform: translateY(-50%); } }
  .select-arrwo {
    position: absolute;
    right: 10px;
    color: #fff; } }


