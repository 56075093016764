.sidebar-group {
  @include breakpoint(md) {
    margin-top: 30px; }
  &.extra-margin1 {
    padding-left: 100px;
    @include breakpoint(md) {
      padding-left: 0px; } }
  &.extra-margin2 {
    padding-left: 50px;
    @include breakpoint(md) {
      padding-left: 0px; } } }
.widget {
  border-radius: 5px;
  margin: 0 0 30px;
  padding: {
    top: 34px;
    bottom: 40px;
    left: 40px;
    right: 30px; }
  border: 1px solid  rgb(230, 230, 230);
  &:last-child {
    margin-bottom: 0px; }
  .widget-title {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
    margin: 0 0 0px;
    line-height: 18px;
    color: #132150;
    padding: 0 0 25px;
    @include afterparent; }


  select {
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    font-family: $heading-font;
    font-weight: 500;
    padding: 1em;
    &:focus {
      outline: none; } }
  &.widget_search {
      .btn {
          padding: {
              left: 15px;
              right: 15px; }
          font-size: 18px; }
      .form-control {
          &:focus {
              border-color: $secondary-color !important; } } }
  &.widget_add {
      border: none;
      padding: 0; } }



.widget {
  .widget-title {
    &:empty {
      margin: 0; } }
  > ul,.menu-header-menu-container ul {
    margin-left: 0;
    padding-left: 0px;
    list-style: outside none none;
    > li {
      margin: 0 0 5px;
      color: #717584;
      padding: 9px 0 0;
      //border-top: 1px solid tint(#ccc, 8%)
      line-height: 1.42857; }
    &:not(.children):not(.sub-menu) > li:last-child {
      margin: 0;
      padding: 9px 0 10px;
      border-bottom: 1px solid transparent; }
    &.children, &.sub-menu {
      margin: 9px 0 0; }
    &.children li, &.sub-menu li {
      position: relative;
      margin: 0 0 10px 15px; }
    &.children li:before, &.sub-menu li:before {
      position: absolute;
      top: 17px;
      left: -15px;
      width: 4px;
      height: 4px;
      content: '';
      border-radius: 50%;
      background-color: $primary-color; }
    li > a {
      text-decoration: none;
      color: #717584;
      transition: all 0.24s ease-in-out;
      &:hover, &:focus {
        text-decoration: none;
        color: $primary-color; } } } }


/* Calendar widget */



.site-footer .widget_calendar {
  padding: 0;
  border-width: 0;
  background-color: transparent; }

.widget.widget_calendar table {
  margin: 0; }

.widget_calendar {
  td, th {
    padding: 0;
    text-align: center;
    line-height: 2.5625; }
  caption {
    margin: 0 0 10px;
    font-weight: bold; }
  tbody a {
    display: block;
    color: #fff;
    background-color: $primary-color;
    font-weight: bold;
    &:hover, &:focus {
      text-decoration: none;
      color: #fff;
      background-color: $body-font-colr; } } }

/* Recent Posts widget */

.widget_recent_entries .post-date {
  display: block;
  margin-top: 3px;
  font-size: 13px; }

/* Recent Comments widget */

.widget_recent_comments ul li {
  font-size: 13px;
  .comment-author-link {
    line-height: 1.42857;
    a {
      display: inline !important;
      margin-top: 0 !important;
      text-decoration: underline;
      color: #a7a7b3;
      font-size: 13px;
      &:hover, &:focus {
        text-decoration: underline;
        color: #757579; } } }
  a {
    display: block !important;
    margin-top: 3px !important;
    font-size: 14px; } }

/* RSS widget */

.widget_rss {
  .rsswidget img {
    margin-top: -3px;
    margin-right: 3px; }
  ul li .rsswidget {
    font-weight: bold; }
  .rss-date, cite {
    display: block;
    margin: 5px 0 0;
    font-size: 13px; }
  .rssSummary {
    margin: 5px 0 0; } }

/* Tag Cloud widget */


.site-footer .widget_tag_cloud {
  padding: 0;
  text-align: center;
  border-width: 0;
  background-color: transparent; }

.tagcloud .tag-cloud-link {
  display: inline-block;
  margin: 0 5px 10px 0;
  padding: 6px 12px 8px;
  font-size: 14px;
  border-radius: 5px;
  color: #717584;
  border: 1px solid rgb(230, 230, 230);
  line-height: 1;
  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
    border-color: 1px solid $primary-color;
    outline: 0;
    background-color: $primary-color; } }

// instragram widget
.instra-item {
  overflow: hidden;
  border-radius: 3px;
  display: block;
  img {
    transform: scale(1.1);
    transition: all 0.64s ease-in-out;
    border-radius: 3px; }
  @include afterparent;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(#000, 0);
    border-radius: 3px; }
  .instra-hover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 30px;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      z-index: 2; } }
  &:hover {
    img {
      transform: scale(1); }
    &:after {
      background: rgba(#000, 0.5); }
    .instra-hover {
      i {
        opacity: 1;
        visibility: visible;
        animation: zoomIn 400ms ease-in-out; } } } }
#instragramslider_active .owl-dots {

  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  z-index: 999; }

#instragramslider_active .owl-dot {
  background: #fff;
  height: 7px;
  width: 7px;
  display: inline-block;
  margin:  0 7px;
  border: 1px solid #ccc;
  transition: all 0.19s $ease-in-sine;
  border-radius: 50%; }


#instragramslider_active .owl-dot.active {
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 0px rgba($primary-color, 0.6);
  animation: pulseservices23 0.8s infinite cubic-bezier(0.66, 0, 0, 1); }
@keyframes pulseservices23 {
  to {
    box-shadow: 0px 0px 0px 8px rgba($primary-color, 0); } }


// widgte social
.widget.widget_social_media {
  ul {
    text-align: left;
    margin: 0;
    padding: 0;
    li {
      border: none;
      margin: 0px !important;
      padding: 0px !important;
      display: inline-block;
      a {
        // display: block
        // height: 35px
        // width: 35px
        // border: 1px solid $secondary-color
        // line-height: 35px
        // color: #fff
        // background: $secondary-color
        // border-radius: 5px
        // text-align: center
        // margin: 0 5px
        font-size: 36px;
        color: $secondary-color;
        display: inline-block;
        margin-right: 15px;
        line-height: 36px;
        &.facebook {
            color: #3667ed;
            &:hover {
                color: darken(#3667ed, 5%); } }
        &.twitter {
            color: #369eff;
            &:hover {
                color: darken(#369eff, 5%); } }
        &.g-pluse {
            color: #ff364e;
            &:hover {
                color: darken(#ff364e, 5%); } }
        &.instra {
            color: #5b3dfe;
            &:hover {
                color: darken(#5b3dfe, 5%); } }

        &:hover {}
        //   background: tint($secondary-color, 10%)
        //   border-color: tint($secondary-color, 10%)
        //color: $secondary-color
        &:last-child {
            a {
                margin-right: 0px;
                border-bottom: none !important; } } } } } }


// widget_bio
.widget.widget_authour_bio {

  .author-bio-data {
    text-align: center;
    .img-thumbnail {
      border-color: $primary-color;
      border-width: 3px; }
    font-size: 14px;
    line-height: 24px; }
  .author-social {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        a {
          display: block;
          color: #999;
          margin: 0 5px;
          transition: all 0.24s ease-in-out;
          &:hover {
            color: $primary-color; }
          &:first-child {
            margin-left: 0px; } } } } } }


// widget catagory
.widget {
    &.widget_categories {
        ul li {
            position: relative;
            &:after {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: '\ef35';
                font-family: icofont;
                color: $secondary-color;
                margin-top: 2px; }
            a {
                padding-left: 20px; } } } }
