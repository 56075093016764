

// top header import
@import "top-header";


.rt-site-header,.main-header {
    position: relative;
    &.rt-fixed-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9; }
    &.rt-sticky {
        &.rt-sticky-active {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 99;
            box-shadow: 0px 2px 3px 0px rgba(#000, 0.1);
            background: linear-gradient( -102deg, rgb(130,116,232) 0%, rgb(198,121,208) 100%);
            .sticky-logo {
                display: block; }
            .brand-logo {
                display: none; } } }
    .sticky-logo {
        display: none; }
    .main-header {
        padding: 10px 0;
        background-color: transparent; } }
