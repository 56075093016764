.testimoninal-active-1 {
    position: relative;
    height: 550px;
    @include breakpoint(xs) {
        height: 485px;
    }
}

.singleTbox-1 {
    position: absolute;
    transition: all 1s ease;
    .inner-content {
        transition: all 0.3s ease;
    }
    .autor-bio {
        transition: all 0.3s ease;
    }
    &.inactive {
        .inner-content,
        .autor-bio {
            display: none;
            @include hidden();
        }
    }
    &:not(.active) {
        //transform: scale(0.55);
        z-index: 99;
        .testi-thumb {
            height: 120px;
            width: 120px;
            background-image: none;
            .inner-bg {
                height: 120px;
                width: 120px;
                box-shadow: 0px 5px 10px 0px rgba(25, 80, 161, 0.2);
            }
            .social-badge {
                display: none;
            }
        }
    }
    &.position-1 {
        transform: scale(0.65);
    }
    &.position-2 {
        left: 0px;
        top: 0px;
        .testi-thumb,
        .inner-bg {
            height: 120px !important;
            width: 120px !important;
        }
        @include breakpoint(lg) {
            .testi-thumb,
            .inner-bg {
                height: 90px !important;
                width: 90px !important;
            }
        }
        @include breakpoint(md) {
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-3 {
        left: 160px;
        top: 40px;
        .testi-thumb,
        .inner-bg {
            height: 140px !important;
            width: 140px !important;
        }
        @include breakpoint(lg) {
            left: 140px;
            .testi-thumb,
            .inner-bg {
                height: 110px !important;
                width: 110px !important;
            }
        }
        @include breakpoint(md) {
            top: 0px;
            left: 80px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 20px;
            left: 60px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-4 {
        left: 20px;
        top: 160px;
        @include breakpoint(lg) {
            .testi-thumb,
            .inner-bg {
                height: 110px !important;
                width: 110px !important;
            }
        }
        @include breakpoint(md) {
            top: 80px;
            left: 0px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 70px;
            left: 10px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-5 {
        left: 155px;
        top: 258px;
        .testi-thumb,
        .inner-bg {
            height: 100px !important;
            width: 100px !important;
        }
        @include breakpoint(lg) {
            top: 215px;
            left: 180px;
            .testi-thumb,
            .inner-bg {
                height: 90px !important;
                width: 90px !important;
            }
        }
        @include breakpoint(md) {
            top: 80px;
            left: 80px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 100px;
            left: 60px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-6 {
        top: 0;
        right: 200px;
        .testi-thumb,
        .inner-bg {
            height: 140px !important;
            width: 140px !important;
        }
        @include breakpoint(lg) {
            //right: 150px;
            .testi-thumb,
            .inner-bg {
                height: 100px !important;
                width: 100px !important;
            }
        }
        @include breakpoint(md) {
            top: 0px;
            right: 0px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 0px;
            right: 0px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-7 {
        top: 40px;
        right: -40px;
        .testi-thumb,
        .inner-bg {
            height: 140px !important;
            width: 140px !important;
        }
        @include breakpoint(lg) {
            right: 40px;
            .testi-thumb,
            .inner-bg {
                height: 100px !important;
                width: 100px !important;
            }
        }
        @include breakpoint(md) {
            top: 0px;
            right: 80px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 0px;
            right: 80px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-8 {
        top: 200px;
        right: 170px;
        .testi-thumb,
        .inner-bg {
            height: 110px !important;
            width: 110px !important;
        }
        @include breakpoint(lg) {
            top: 140px;
            .inner-bg {
                height: 90px !important;
                width: 90px !important;
            }
        }
        @include breakpoint(md) {
            top: 80px;
            right: 0px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(md) {
            top: 60px;
            right: 0px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-9 {
        top: 240px;
        right: -40px;
        .testi-thumb,
        .inner-bg {
            height: 110px !important;
            width: 110px !important;
        }
        @include breakpoint(lg) {
            right: 40px;
            top: 188px;
            .testi-thumb,
            .inner-bg {
                height: 90px !important;
                width: 90px !important;
            }
        }
        @include breakpoint(md) {
            top: 80px;
            right: 80px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 60px;
            right: 60px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.position-10 {
        top: 350px;
        right: 80px;
        .testi-thumb,
        .inner-bg {
            height: 100px !important;
            width: 100px !important;
        }
        @include breakpoint(lg) {
            top: 300px;
            right: 150px;
            .testi-thumb,
            .inner-bg {
                height: 80px !important;
                width: 80px !important;
            }
        }
        @include breakpoint(md) {
            top: 160px;
            right: 40px;
            .testi-thumb,
            .inner-bg {
                height: 70px !important;
                width: 70px !important;
            }
        }
        @include breakpoint(xs) {
            top: 120px;
            right: 30px;
            .testi-thumb,
            .inner-bg {
                height: 40px !important;
                width: 40px !important;
            }
        }
    }
    &.active {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        @include breakpoint(md) {
            width: 100%;
        }
        .inner-content,
        .autor-bio {
            transition-delay: 1s
        }
    }
}