.rt-cart-box {
    position: absolute;
    right: 0;
    top: 140%;
    width: 350px;
    background-color: #fff;
    z-index: 999;
    padding: 30px;
    box-shadow: 0px 10px 23.49px 3.51px rgba(0, 0, 0, 0.14);
    border: 1px solid rgba($primary-color, 0.4);
    height: 524px;
    overflow: auto;
    @include rt-trs(0.24s);
    @include hidden();
    display: none;
    z-index: -2;
    &.active {
        @include vis();
        z-index: 9;
        display: block; }
    .rt-single-crtitem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px; } } }
