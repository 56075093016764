
// include nav search
@import "nav-search";
@import "offcanvas";
@import "nav-cart";

.rt-nav-tolls {
    margin-left: 30px;
    position: relative;
    .rt-search-open,.open-cart-opt {
        cursor: pointer; }
    span {
        @include afterparent();



        a {
            color: #fff;
            @include rt-trs(0.245s);
            &:hover {
                color: $primary-color; } } } }

