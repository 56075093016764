@charset "UTF-8";

/// Provides an easy way to include a clearfix for containing floats.
///
/// @link https://goo.gl/yP5hiZ
///
/// @example scss
///   .element {
///     @include clearfix;
///   }
///
///   // CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: block;
///   }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
