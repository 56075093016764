// wordpress default comments

.comments-area {
  margin: 0 0 30px;
  padding: 0;
  background-color: transparent;
  color: #333;
  h1,h2,h3,h4,h5,h6 ,p {
    color: #333; } }
.comments-area {
  margin-top: 40px; }

.comment-list + .comment-respond, .comment-navigation + .comment-respond {
  padding: 30px 0 0; }

.comments-title, .comment-reply-title {

  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  margin:  0 0 30px; }


.comments-title {
  margin: 0 0 30px;
  color: #333; }

.comment-list {
  margin: 0;
  list-style: none;
  @include clearfix();
  .comment-body, .pingback, .trackback {
    margin: 0;
    padding: 20px 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: #fff; }
  .children {
    margin: 0;
    list-style: none;
    > li {
      padding: 0 0 0 15px; } } }
.comment-list li {
  @include clearfix(); }

.comment-author {
  margin: 0 0 5px;
  color: #333;
  font-size: 14px;
  line-height: 1.42857;
  .fn {
    font-weight: bold; }
  .avatar {
    position: relative;
    float: left;
    width: 65px;
    height: 65px;
    margin: 0 10px 0 0; } }

.bypostauthor > article .fn {
  padding-left: 7px;
  background: #feffa3;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#feffa3+0,000000+100&1+0,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#feffa3), to(transparent));
  background: linear-gradient(to right, #feffa3 0%, transparent 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffa3', endColorstr='#00000000',GradientType=1 );
  /* IE6-9*/ }

.comment-author .url {
  text-decoration: underline;
  color: #333;
  letter-spacing: 1px;
  &:hover, &:focus {
    color: $primary-color; } }

.comment-metadata, .pingback .edit-link {
  color: #a7a7b3;
  font-size: 13px;
  line-height: 1.42857; }

.comment-metadata {
  margin: 0 0 15px;
  a {
    color: #a7a7b3; } }

.pingback .comment-edit-link {
  color: #a7a7b3; }

.comment-metadata a {
  &:hover, &:focus {
    color: $primary-color; } }

.pingback .comment-edit-link {
  &:hover, &:focus {
    color: #7e7e90; } }

.comment-metadata .edit-link, .pingback .edit-link {
  display: inline-block; }

.comment-metadata .edit-link:before, .pingback .edit-link:before {
  display: inline-block;
  padding: 0 8px 0 4px;
  content: '\002f';
  opacity: .7; }

.comment-content {
  ul, ol {
    margin: 0 0 20px 20px; }
  li > {}
  ul, ol {
    margin-bottom: 0; } }

.comment-reply-link {
  display: inline-block;
  margin: 20px 0 0;
  padding: 9px 15px 10px;
  color: #a7a7b3;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  line-height: 1;
  &:hover, &:focus {
    text-decoration: none;
    color: #333;
    border-color: $primary-color;
    outline: 0;
    background-color: $primary-color; } }

.comment-list .comment-respond {
  padding: 20px 0; }

.comment-notes, .comment-awaiting-moderation, .logged-in-as, .form-allowed-tags {
  margin: 0 0 20px;
  color: #a7a7b3;
  font-size: 14px; }

.no-comments {
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #e7e7e7;
  font-weight: bold; }

.comment-navigation + .no-comments {
  padding: 0;
  border-width: 0; }

.form-allowed-tags code {
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace; }

.form-submit {
  margin: 0;
  &:before, &:after {
    display: table;
    clear: both;
    content: ''; } }

.required {
  color: $primary-color;
  font-family: Georgia, Times, serif; }

.comment-reply-title small {
  margin: 0 0 0 5px;
  font-size: 100%;
  a {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    color: #a7a7b3;
    &:hover, &:focus {
      color: #7e7e90; }
    &::before {
      position: relative;
      top: 0;
      display: inline-block;
      content: '\f00d';
      vertical-align: text-top;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: auto; } } }
.comment-author.vcard img {
  border-radius: 50%; }
.post-navigation {
  border-top: 2px solid $body-font-colr;
  padding-top: 30px; }

.nav-links {
  a {
    word-break: break-all;
    color: $body-font-colr;
    transition: all 0.24s ease-in-out;
    &:hover {
      color: $primary-color; } } }
pre {
  background: #36353b !important;
  padding: 2em;
  @include breakpoint(xs) {
    padding: 1em; }
  font-style: italic;
  font-size: 16px;
  color: tint($primary-color, 50%);
  font-family: $body-font;
  position: relative;
  z-index: 1; }
.comment-form {
  input {
    &:focus {
      border: 1px solid $primary-color !important; } } }


.new-cmnt-share {
  a {
    color: #1e375a;
    font-size: 14px;
    text-transform: capitalize;
    margin-right: 5px;
    &:last-child {
      margin-right: 0px; }
    span {
      i {
        font-size: 4px;
        color: rgb(131, 136, 168); } }
    &:hover {
      color: $primary-color; } } }
