.single-rt-banner {
    @include afterparent();
    background-color: #fff;
    .banner-add-img {
        @include afterparent;
        @include breakpoint(md) {
          display: none; }
        .sold_img {
            position: absolute;
            right: -100px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 4;

            @include breakpoint(lg) {
                right: 0px; } } } }
.rt-banner-area {
    position: relative; }
.rt-dot-list {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint(lg) {
        right: auto;
        left: 70px;
        top: auto;
        bottom: 30px; }
    li {
        display: block;
        color: #ffffff;
        margin-bottom: 5px;
        cursor: pointer;
        &:last-child {
            margin-bottom: 0px; }
        @include afterparent();
        &:before {
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            @include hw(16px);
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0px 8px 8px 0px rgba(85, 78, 253, 0.4);
            @include breakpoint(lg) {
                right: auto;
                left: -30px; } }
        &.active {
            &:before {
                background: linear-gradient( 147deg, rgb(243,0,112) 0%, rgb(235,126,10) 100%); } } } }


.rt-banner-height {
    height: 900px;
    @include breakpoint(md) {
        height: 550px; } }
.domain-searh-form {
    @include afterparent();
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(24, 13, 91, 0.15);
    border-radius: 999px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 20px;
    @include breakpoint(xs) {
        padding: 15px 30px;
        height: auto;
        border-radius: 15px; }
    input[type="text"] {
        background: transparent;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
        border: none;
        width: 200px;
        @include breakpoint(xs) {
            width: auto;
            @include flex-width(70%); }
        &::placeholder {
            font-size: 16px;
            color: #5d5c64;
            font-weight: 300; }
        &:focus {
            outline: none; } }
    span.bar {
        height: 20px;
        top: 50%;
        position: relative;
        transform: translateY(-50%);
        width: 1px;
        background-color: rgb(199, 199, 199);
        @include breakpoint(xs) {
            display: none; } }
    select {
        background: transparent;
        border: none;
        color: #5d5c64;
        width: 120px;
        @include breakpoint(xs) {
            @include flex-width(20%); }
        &:focus {
            outline: none; } }
    button {
        padding: 0px 56px !important;
        border: none;
        @include breakpoint(xs) {
            margin-top: 15px;
            margin-left: auto;
            margin-right: auto;
            padding: 13px 56px !important; }
        &:focus {
            outline: none; }
        span {
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%); } } }
.domain-searh-form2 {
    @include afterparent();
    .form-control {
        height: 51px;
        box-shadow: 0px 5px 20px 0px rgba(24, 13, 91, 0.15);
        border-radius: 999px !important;
        border: none;
        padding-left: 20px;
        padding-right: 175px;
        font-size: 16px;
        @include afterparent();
        &::placeholder {
            font-size: 16px; } }
    .rt-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); } }



// slider dot and navigation
.rt-slider-active {
    z-index: auto;
    .owl-dots {
        position: absolute;
        right: 100px;
        z-index: 8;
        transform: translateY(-50%);
        top: 50%;
        @include breakpoint(lg) {
            right: 50px; }
        @include breakpoint(md) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            top: auto;
            bottom: 30px; } }
    .owl-dot {
        @include hw(16px);
        background-color: #fff !important;
        display: block;
        margin-bottom: 15px;
        border-radius: 50%;
        box-shadow: 0px 8px 8px 0px rgba(85, 78, 253, 0.4);
        @include breakpoint(md) {
            display: inline-block;
            margin: 0 5px; }
        &.active {
            background: linear-gradient( 147deg, rgb(243,0,112) 0%, rgb(235,126,10) 100%); }
        &:focus {
            outline: none; }
        &:last-child {
            margin: 0px; } }
    .owl-nav .owl-prev, .owl-nav .owl-next {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        @include hw(45px);
        background-color: $primary-color !important;
        text-align: center;
        border-radius: 50%;
        color: #fff !important;
        font-size: 20px;
        &:focus {
            outline: none; } }
    .owl-nav .owl-next {
        left: auto;
        right: 30px; } }



