/*1.0 Root Css*/
@import "color-root";
@import "htmlprefix";
/*1.1 Default Css*/
@import "default-setting";
@import "custom-animation";
/*1.2 typography Css*/
@import "typography";





// body
//     +breakpoint(xxl)
//       background-color: gold
//     +breakpoint(xl)
//       background-color: purple
//     +breakpoint(lg)
//       background-color: red
//     +breakpoint(md)
//       background-color: yellow
//     +breakpoint(xs)
//       background-color: pink
