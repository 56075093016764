.rt-one-page-menu  ul >  li.nav-item >  a.nav-link {
	font-size: $onepage_menu_font_size;
	color: $onepage_menu_font_color;
	margin-left: $onepage_menu_item_margin;
	margin-right: $onepage_menu_item_margin;
	padding: 0px;
	position: relative;
	text-transform: capitalize;
	transition: all 0.24s ease-in-out;
	font-family: $onepage_menu_font;
	font-weight: 600; }

.rt-one-page-menu  ul >  li.nav-item >  a.nav-link:focus {
	outline: none; }






.rt-one-page-menu {
	.dropdown-menu {
		background: $onepage_sub_menubg;
		transform: translate3d(0, 15px, 0);
		display: block;
		margin-top: 0px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.15s;
		border: none !important;
		outline: none;
		box-shadow: $onepage_sub_menushadow;
		padding: 0px;
		border-radius: $onepage_sub_menu_border-radius;
		min-width: 200px; } }
.rt-one-page-menu .dropdown:hover .dropdown-menu, .rt-one-page-menu.dropdown.show .dropdown-menu {
	@include vis();
	transform: translate3d(0, 0, 0); }


.rt-one-page-menu .dropdown-toggle:after {
	border: none; }
.dropdown-item {
	color: #3d404d !important;
	transition: all 0.24s ease-in-out;
	font-size: 14px;
	text-transform: capitalize;
	padding: 8px 20px; }

.dropdown-item {
    font-weight: 500; }
.dropdown-item:hover {
	background: #31348c;
	color: #fff !important;
	padding-left: 25px; }
.home-two .dropdown-item:hover {
	background: $secondary-color; }
.rt-one-page-menu.homepage2 .dropdown-item:hover {
	background: $secondary-color; }




// mobile menu option
.navbar-toggler-icon {
  display: block;
  width: 35px;
  height: 2px;
  background: #31348c;
  margin-bottom: 6px;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  &:nth-child(2) {
  	width: 20px; } }
.home-two {
	.navbar-toggler-icon {
		background: #fff; } }
.navbar-toggler:hover .navbar-toggler-icon:nth-child(2) {
  margin-left: 8px; }
.navbar-toggler:focus {
	outline: none; }
.navbar-toggler.cg {
  .navbar-toggler-icon:nth-child(1) {
    transform: rotate(45deg) translateX(6px); }
  .navbar-toggler-icon:nth-child(2) {
    @include hidden();
    margin-left: -20px;
 }    //transition-delay: 0.14s
  .navbar-toggler-icon:nth-child(3) {
    transform: rotate(-45deg) translate(10px, -4px);
    width: 35px; } }
.rt-site-header.rt-sticky-active  .navbar-toggler-icon {
	background: #fff; }
// bs mobile menu change
// video page menu css
.site-header.rt-sticky-active .rt-one-page-menu.homepage2.whitenav  ul >  li.nav-item >  a.nav-link {
	color: #8b8b8b; }











.rt-one-page-menu ul > li.dropdown:after {
	position: absolute;
	right: -3px;
	content: "\ea99";
	font-family: "icofont";
	top: 50%;
	transform: translateY(-50%);
	color: #fff; }

.site-header.rt-sticky-active .rt-one-page-menu ul > li.dropdown:after {
	right: -6px; }


// menu title
span.menu-title {
	display: block;
	color: #292d46;
	text-transform: capitalize;
	padding: 5px 0;
	border-bottom: 1px dashed #666;
	padding-left: 15px;
	font-size: 16px;
	font-weight: 500; }





// dark nav
.rt-nav-dark.rt-one-page-menu {
	ul>li.nav-item>a.nav-link {
		color: #3d404d;
		&:hover {
			color: $secondary-color; } }
	ul > li.dropdown:after {
		color: #3d404d; }
	ul > li.dropdown:hover:after {
		color: $secondary-color; }
	.dropdown-item {
		&:hover {
		  background: $secondary-color; } } }






@media  (max-width:  991.98px) {
	.home-two .navbar-nav {
		background: $secondary-color; } }
@media  (max-width:  991.98px) {
  .navbar-nav {
  	background: #31348c;
  	padding: 15px;
  	border-radius: 3px 3px 0px 0px; }
  .navbar-nav {
    margin-top: 30px; }

  .rt-one-page-menu .dropdown-menu {
  	height: 0px; }
  .rt-one-page-menu ul > li.dropdown:hover	>  .dropdown-menu {
  	height: auto; }
  .rt-one-page-menu  ul >  li.nav-item >  a.nav-link {
  	padding: 2px 0;
  	font-size: 14px; }
  .rt-one-page-menu ul > li.nav-item > a.nav-link:after {
  	display: none; }
  .homepage2 .navbar-nav {
  	background-image: linear-gradient( -90deg, rgb(39,194,253) 0%, rgb(127,70,255) 100%); }
  .rt-one-page-menu ul > li.dropdown:after {
  	display: none; } }



@media  (max-width:  991.98px) {
	.rt-nav-dark.rt-one-page-menu {
		ul>li.nav-item>a.nav-link {
			color: #fff;
			&:hover {
				color: #fff; } }
		.navbar-nav, .navbar-toggler-icon {
			background: $secondary-color; } } }





