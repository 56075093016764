.domain-table {
    thead {
        th {
            padding: 20px 20px;
            border-color: rgb(225, 223, 223);
            border-bottom: 1px solid rgb(225, 223, 223);
            text-transform: uppercase; } }
    tbody {
        th, td {
            padding: 25px 20px;
            vertical-align: middle; }

        tr {
            border-bottom: 1px solid rgb(225, 223, 223);
            @include rt-trs(0.24s);
            &:hover {
                box-shadow: 0px 0px 15px 0px rgba(29, 17, 128, 0.3);
                .rt-gradient2 {
                    &:after {
                        @include vis(); } } } } } }



