.rt-breadcump {
  margin-bottom: 100px;
  @include breakpoint(xs) {
    margin-bottom: 70px; }
  background-color: #1d2a49;
  position: relative;
  color: #fff;
  a, p {
    color: #fff;
    font-size: 16px;
    position: relative; }
  &.breaducump-style-2 {
    background-color: transparent; }






  .rt-breadcump-height {
    height: 583px; }
  .rt-page-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // &:after
    //   +after(absolute, 0, 0, 100%, 100%)
    //   background: linear-gradient( 8deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%)
 } }    //   +opacity(0.8)



