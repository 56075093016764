.filter-list {
    margin: 0;
    padding: 10px;
    list-style: none;
    text-align: center;
    border: 1px solid  rgb(188, 188, 188);
    border-radius: 999px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
        display: inline-block;
        background: linear-gradient( -102deg, rgb(130,116,232) 0%, rgb(198,121,208) 100%);
        padding: 10px 31px;
        border-radius: 999px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 17px;
        margin-right: 10px;

        position: relative;
        z-index: 1;
        @include rt-trs(0.24s);
        cursor: pointer;
        @include breakpoint(lg) {
          padding: 14px 20px; }
        @include breakpoint(md) {
          margin: 5px; }
        &.active, &:hover {
            background-color: transparent;
            box-shadow: 0px 13px 40px 0px rgba(13, 21, 75, 0.2);
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient( 0deg, rgb(255,170,87) 0%, rgb(254,92,118) 100%);
                content: '';
                border-radius: 999px;
                @include rt-trs(0.24s);
                z-index: -1; } }
        &:last-child {
            margin-right: 0px; } } }


.tbanavs {
    margin: 0;
    padding: 10px;
    list-style: none;
    border: 1px solid  rgb(188, 188, 188);
    border-radius: 999px;
    justify-content: space-between;
    @include breakpoint(xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0px; }
    li  a {
        display: inline-block;
        background: linear-gradient( -102deg, rgb(130,116,232) 0%, rgb(198,121,208) 100%);
        padding: 10px 31px;
        border-radius: 999px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 17px;
        margin-right: 10px;
        white-space: nowrap;
        position: relative;
        z-index: 1;
        @include rt-trs(0.24s);
        cursor: pointer;
        @include breakpoint(lg) {
          padding: 14px 20px; }
        @include breakpoint(md) {
          margin: 5px; }
        &.active, &:hover {
            background-color: transparent;
            box-shadow: 0px 13px 40px 0px rgba(13, 21, 75, 0.2);
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient( 0deg, rgb(255,170,87) 0%, rgb(254,92,118) 100%);
                content: '';
                border-radius: 999px;
                @include rt-trs(0.24s);
                z-index: -1; } }
        &:last-child {
            margin-right: 0px; } } }
// portfolio box style
.portfolio-box-1 {
    height: 350px;
    border: 1px solid rgba(#fff, 0.4);
    @extend .rtbgprefix-cover;
    border-radius: 5px;
    margin-bottom: 30px;
    @include afterparent();
    &:after {
        background-image: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0.58) 36%, rgba(255,255,255,0) 100%, #fff 100%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: 5px;
        z-index: -1; }
    .portfolio-badge {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 80px;
        background-image: linear-gradient( 0deg, rgb(255,170,87) 0%, rgb(254,92,118) 100%);
        box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.4);
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        span {
            sup {
                font-size: 14px; }
            &:nth-child(1) {
                font-size: 14px; }
            &:nth-child(2) {
                font-size: 24px;
                font-weight: 600; } } }

    .inner-content {
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 100%;
        text-align: center;
        h6 {
            font-size: 18px; }
        p {
            span {
                font-size: 14px;
                i {
                    color: #ffab53; } } } } }







