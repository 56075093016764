$theme-colors: () !default;
$theme-colors: map-merge(("Primary__color": $primary-color,
    "Secondary__color": $secondary-color,

  ), $theme-colors);



:root {


  @each $color,
  $value in $theme-colors {
    --#{$color}: #{$value};
  }




}


// color
.primary-color,
a.primary-color {
  color: $primary-color;

}

.secondary-color,
a.secondary-color {
  color: $secondary-color;
}

.primary-color-bg {
  background-color: $primary-color;
}

.secondary-color-bg {
  background-color: $secondary-color;
}

// section background color
.has-gray-bg {
  background-color: gray;
}

.rt-dark {
  background-color: #151932;
}

.rt-light-bg {
  background-color: #fff;
}

.rt-light-gray {
  background-color: #eef4fb;
}

.rt-dim-light {
  background-color: #f4eafe;
}
.rt-dim-light2 {
  background-color: #f7f9ff;
}
.rt-bgtransparent{
  background: transparent !important;
}

// text color 
.text-333,
a.text-333 {
  color: #333;
}

.text-555,
a.text-555 {
  color: #555;
}

.text-777,
a.text-777 {
  color: #777777;
}

.text-878,
a.text-777 {
  color: #878484;
}

.text-2f7,
a.text-777 {
  color: #2f7000;
}

.text-656 {
  color: #656f7f;
}

.text-444 {
  color: #444;
}

.heading-color-1 {
  color: #000;
}

.text-424 {
  color: #42435d;

  p,
  a {
    color: #42435d;
  }
}

.text-000 {
  color: #000;

  p,
  a {
    color: #000;
  }
}


.text-234{
  color: #234d94;
}
.text-eb7{
  color: #eb7e0a;
}
.text-605{
  color: #6051cf;
}
.text-338{
  color: #33880b;
}
.text-323639{
  color: #323639;
}
.text-422{
  color: #4228a8;
}
// text gradinet color 

.text-gradinet-primary {
  background: $gradient-primary;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-primary {
  background: $gradient-primary;
}
// graident color 

.rt-gradient-1{
  background-image: linear-gradient(90deg, rgb(126, 93, 254) 0%, rgb(170, 103, 223) 100%);
}
.rt-gradient-2{
  background-image: linear-gradient(90deg, rgb(80, 82, 254) 0%, rgb(75, 201, 254) 100%);
}
.rt-gradient-3{
  background-image: linear-gradient(90deg, rgb(233, 129, 59) 0%, rgb(253, 178, 60) 100%);
}


.rt-gradient-4{
  background-image: linear-gradient(7deg, rgb(130, 116, 232) 0%, rgb(198, 121, 208) 100%)
}
.text-body-color{
  color: $body-font-colr;
}
a.text-body-color:hover{
  color: $primary-color;
}