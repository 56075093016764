// login modal
.rt-lgoinmodal {
    .modal-content {
        border-radius: 12px;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
        .modal-body {
            padding: 0px;
            .rt-modal-headr {
                text-align: center;
                padding-top: 20px;
                h4 {
                    font-size: 20px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 0 0 15px; }
                p {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    color: #3d446f;
                    @include breakpoint(md) {
                      width: 100%; } }
                &.one {
                    &.hide-cac {
                        display: none; } }
                &.two {
                    display: none;
                    &.show-cac {
                        display: block; } } }

            .rt-modal-input {
                padding: {
                    left: 30px;
                    right: 30px; }
                input[type="submit"], button {
                    margin-top: 30px;
                    &:hover {
                        box-shadow: 0px 10px 36px 0px rgba(73, 105, 253, 0.8); } }
                .ac-register {
                    margin-top: 25px;
                    text-align: center;
                    span {
                        font-size: 14px;
                        color: #0d154b;
                        a {
                            color: red;
                            font-size: 16px;
                            font-weight: 700;
                            color: #000;
                            &:hover {
                                color: $primary-color; } } } }
                &.one {
                    &.hide-cac {
                        display: none; } }
                &.two {
                    display: none;
                    &.show-cac {
                        display: block; } } }
            .rt-modal-footer {
                background-image: linear-gradient( -8deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%);
                margin-top: 40px;
                position: relative;
                padding: {
                    top: 45px;
                    left: 30px;
                    right: 30px;
                    bottom: 30px; }
                text-align: center;
                h4 {

                    font-size: 20px;
                    font-weight: 700;
                    text-transform: capitalize;
                    margin: 0 0 25px;
                    color: #fff; }
                span {
                    @include hw(45px);
                    text-align: center;
                    border: 1px solid rgba(#fff , 0.8);
                    display: inline-block;
                    border-radius: 50%;
                    font-size: 16px;
                    box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.4);
                    color: #496cfe;
                    background-color: #fff;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -25px; } } } } }




