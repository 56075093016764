/*2.0 Banner Css*/
@import "banner";
/*2.1 IconBox Css*/
@import "icon-box";
/*2.2 Services Css*/
@import "services";
/*2.3 portfolio Css*/
@import "portfolio";
/*2.4 testimonials Css*/
@import "testimonials";
/*2.5 Callto Action Css*/
@import "cta";
/*2.6 counter-styleCss*/
@import "counter-style";
/*2.7 feature Css*/
@import "feature";
/*2.8 price Css*/
@import "price";
/*2.9 gloabl page Css*/
@import "global-page";
/*2.10 team Css*/
@import "team";
