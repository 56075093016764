.pagination.rt-paganation {
  padding-top: 25px;
  @include breakpoint(xs) {
    padding-top: 10px; }
  li.page-item {
    margin-right: 15px;
    &:last-child {
        margin-right: 0px; }
    &.active {
      .page-link {
        background-image: linear-gradient( 8deg, rgb(153,50,245) 0%, rgb(113,51,243) 17%, rgb(73,51,241) 45%, rgb(73,80,248) 71%, rgb(73,108,254) 100%);
        color: #fff;
        border-color: transparent;
        box-shadow: 0px 12px 20px 0px rgba(46, 70, 198, 0.2);
        &:hover {
          color: #fff; } } }
    .page-link {
      border-color: rgb(247, 248, 252);
      color: #505b6d;
      padding: 0px;
      background-color: rgb(247, 248, 252);
      @include hw(42px);
      text-align: center;
      border-radius: 1px;
      font-size: 18px;
      box-shadow: 0px 4px 10px 0px rgba(12, 124, 255, 0.15);
      border-radius: 3px;
      font-family: $heading-font;
      font-weight: 500;
      &:hover {
        color: #fff;
        background-image: linear-gradient( -8deg, rgb(153,50,245) 0%, rgb(113,51,243) 17%, rgb(73,51,241) 45%, rgb(73,80,248) 71%, rgb(73,108,254) 100%); }
      &:focus {
        box-shadow: none; } } } }
