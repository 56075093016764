.rt-hidden-search {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    overflow: hidden;
    background-color: #fff;
    transition: all .4s ease-in-out;
    .btn {
        background-color: $primary-color;
        color: #fff;
        font-size: 20px; }
    .form-control {
        background: transparent;
        border-color: $primary-color;
        height: 52px;
        padding-left: 28px;
        font-size: 16px;
        &::placeholder {
            color: #333;
            font-size: 16px; }
        &:focus, &:hover {
            outline: none;
            box-shadow: none; } }
    @include hidden();
    .rt-search-close {
        position: absolute;
        right: 30px;
        top: 30px;
        color: $primary-color;
        font-size: 20px;
        cursor: pointer; }
    &.rt-search-active {
        @include vis();
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 0px;
        animation: scale-up-bottom; } }
.search-height {
    height: 100vh; }
