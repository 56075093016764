.cta-box-1 {
    height: 170px;
    border-radius: 10px;
    padding: {
        left: 30px;
        right: 30px; }
    h4 {
        font-size: 20px;
        font-weight: 600;
        span {
            font-size: 18px;
            font-weight: 400; } }
    p {
        font-size: 14px; }
    @include breakpoint(xs) {
      height: auto;
      padding: 30px; } }
.cta-box-2 {
    height: 500px;
    padding: {
        left: 30px;
        right: 30px; }
    border-radius: 30px; }
