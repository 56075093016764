.main-menu ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-right: -13px;
  li {
    display: inline-block;
    position: relative;
    a {
      color: $menu_item_color;
      display: block;
      font-size: $menu_font_size;
      font-family: $menu_font_family;
      padding: 0px 13px;
      @include breakpoint(lg) {
        padding: 0 5px; }
      font-weight: 500;
      text-transform: capitalize;
      transition: all 0.34s ease-in-out;
      &:hover {
        text-decoration: none; } } } }
.main-menu a:foucs {
  outline: none;
  text-decoration: none; }
.main-menu > ul > li.current-menu-item > a {
  color: $menu_item_hover_color; }
/*change hover color*/
.main-menu > ul > li:hover > a {
  color: $menu_item_active_color; }
/*li dot hover change*/



/*men item carve icon*/
.main-menu > ul > li.menu-item-has-children > a:after {
  content: "\ea99";
  margin-left: 4px;
  font-family: "icofont"; }

/*sub menu*/
.main-menu > ul > li.menu-item-has-children > ul.sub-menu {
  position: absolute;
  left: 0;
  top: 120%;
  background: $submenu_bg;
  z-index: 999;
  color: #fff;
  text-align: left;
  border-radius: $submenu_border_radius;
  min-width: 200px;
  transition: all 0.42s ease-in-out;
  @include hidden(); }

.main-menu > ul > li.menu-item-has-children:hover > ul.sub-menu {
  @include vis();
  top: 100%; }

.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li,
.main-menu ul ul li {
  display: block; }

.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li > a,
.main-menu ul ul li a {
  display: block;
  padding: 8px 15px;
  border-bottom: $submenu_item_border_color;
  text-transform: capitalize;
  color: $submenu_item_color;
  font-weight: 400;
  @include afterparent(); }
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:last-child > a,
.main-menu ul ul li:last-child >  a {
  border-bottom: 1px solid transparent !important; }
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:hover > a,
.main-menu ul ul > li:hover > a {
  padding-left: 23px;
  color: #fff;
  background: $submenu_hover_bg; }
/* */// drop menu cart*/
.main-menu   ul ul li.menu-item-has-children > a:after {
  content: "\eab8";
  position: absolute;
  font-family: "icofont";
  right: 10px; }
.main-menu   ul ul li.menu-item-has-children.change-icon > a:after {
  content: "\f0da"; }
.main-menu ul ul {
  position: absolute;
  left: 202px;
  top: 30px;
  background: $submenu_bg;
  border: $submenu_border;
  border-radius: 3px;
  z-index: 999;
  color: #333;
  text-align: left;
  min-width: 200px;
  transition: all 0.42s ease-in-out;
  @include hidden(); }

.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children:hover > ul.sub-menu,
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children:hover > ul.sub-menu {
  top: 0px;
  @include vis(); }

.mobile-menu {
  display: none; }

.menu-click {
  background: $mobile_menu_hambugrar_bg_color;
  border: 1px solid $mobile_menu_hambugrar_border_color;
  cursor: pointer;
  display: inline-block;
  position: static;
  right: 10px;
  padding: 8px 7px 4px;
  top: -60px;
  transition: all 0.34s $ease-in-out-back;
  z-index: 9999;
  margin-left: 15px; }

.menu-click span {
  transition: all 0.34s $ease-in-out-back;
  width: 30px;
  background: $mobile_menu_hambugrar_bar_color;
  height: 2px;
  margin-bottom: 4px;
  display: block; }
//experintam mobile menu
.mobile-menu {
  display: none; }

// white menu css
@media (min-width: 992px) {
  .white-menu {
    .main-menu >  ul > li  > a {

      color: #fff;
      &:hover {
        color: $secondary-color; } }
    .main-menu >  ul > li.current-menu-item  > a {
      color: $secondary-color;
      &:hover {
        color: $secondary-color; } }
    &.home-one {
      .main-menu ul ul>li:hover>a, .main-menu>ul>li.menu-item-has-children>ul.sub-menu>li:hover>a {
        background: $primary-color; } }
    &.home-two {
      .main-menu >  ul > li  > a {
        &:hover {
          color: $secondary-color; } }
      .main-menu >  ul > li.current-menu-item  > a {
        color: #fff;
        &:hover {
          color: $secondary-color; } }
      .main-menu ul ul>li:hover>a, .main-menu>ul>li.menu-item-has-children>ul.sub-menu>li:hover>a {
        background: $secondary-color; } } } }
