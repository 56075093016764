




// bootstrap button style
.rt-btn {
  font-size: $button_font_size;
  line-height: 24px;
  border-radius: $buton_border_radius;
  padding: $button_padding;
  font-weight: $button_font_weight;
  transition: all 0.24s $ease-in-cubic;
  font-family: $button_font_family;
  border-width: $button_border_width;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  &.long {
    padding: 12px 68px;
    span {
      position: absolute;
      right: 4px;
      transform: translateY(-50%);
      top: 50%; } }
  &.pill {
    border-radius: 999px; }
  &.rt-rounded {
    border-radius: 10px; }
  &.rt-sm {
    font-size: 14px;
    padding: 14px 25px; }
  &.rt-sm2 {
    font-size: 14px;
    padding: 10px 35px; }
  &.rt-sm3 {
    font-size: 13px;
    padding: 10px 30px; }
  &.rt-xl {
    padding: 16px 100px; }
  &.d-block {
    display: block !important;
    width: 100%; }
  &.rt-video-primary {
    @include hw(80px);
    background: $primary-color;
    color: #fff;
    box-shadow: 0px 0px 0px 8px rgba(31, 211, 165, 0.3);
    border-radius: 50%;
    padding: 0px;
    @include afterparent;
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '\ec74';
      font-family: 'icofont';
      transform: translate(-50%, -50%); } }
  &.rt-video-secondary {
    height: 55px;
    width: 55px;
    line-height: 55px;
    background: $secondary-color;
    color: #fff;
    box-shadow: 0px 0px 0px 8px rgba($secondary-color, 0.3);
    border-radius: 50%;
    padding: 0px;
    @include afterparent;
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '\ec74';
      font-family: 'icofont';
      transform: translate(-50%, -50%); } }
  &.rt-video-light {
    height: 60px;
    width: 60px;
    line-height: 60px;
    background: #fff;
    color: $primary-color;
    box-shadow: 0px 0px 0px 0px rgba(#fff, 0.7);
    border-radius: 50%;
    padding: 0px;
    animation: rt-pulse 1.25s infinite;
    @include afterparent;
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '\ec74';
      font-family: 'icofont';
      transform: translate(-50%, -50%); } }

  @include breakpoint(xs) {
    padding: 16px 37px;
    font-size: 14px; }
  span {
    @include hw(40px);
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    margin-left: 6px;
    font-size: 18px; } }



// animation

@keyframes rt-pulse {
  to {
    box-shadow: 0px 0px 0px 15px rgba(#fff, 0); } }


.rt-button-group {
  a {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px; } } }

.rt-primary {
  background: $primary-color;
  border-color: $primary-color;
  color: #fff;

  &:hover, &:focus, &:active {
    background: shade($primary-color, 15%);
    border-color: shade($primary-color, 15%);
    color: #fff; } }






.rt-dark-btn {
  background: #000;
  border-color: #000;
  color: #fff !important;
  &:hover, &:focus, &:active {
    background: lighten(#000, 15%);
    border-color: lighten(#000, 15%); } }

.rt-secondary {
  background: $secondary-color;
  border-color: $secondary-color;
  color: #fff;
  &:hover, &:focus, &:active {
    background: shade($secondary-color, 15%);
    border-color: shade($secondary-color, 15%); } }


.rt-light {
  background: #fff;
  border-color: #fff;
  color: $primary-color;
  &:hover, &:focus, &:active {
    background: $primary-color;
    border-color: $primary-color;
    color: #fff; } }


// outline button
.rt-outline-primary {
  border-color: $primary-color;
  color: $primary-color;
  &:hover, &:focus, &:active {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff; } }
.rt-outline-gray {
  border: 1px solid  rgba(#000, 0.40);
  color: $body-font-colr;
  &:hover, &:focus, &:active {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff !important; } }


.rt-outline-secondary {
  border-color: $secondary-color;
  color: $primary-color;
  &:hover, &:focus, &:active {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $primary-color; } }


.rt-outline-light {
  border: 1px solid #fff;
  color: #ffffff;
  &:hover, &:focus, &:active {
    background: shade($primary-color, 15%);
    border-color: shade($primary-color, 15%);
    color: #fff; } }



.rt-gradient {
  background: $gradient-primary;
  color: #fff;
  border: none;
  @include afterparent();
  span {
    background-color: rgba(#fff, 0.302);
    color: #fff; }
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: $gradient-primary_reverse;
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff;
    border-radius: 10px; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } }
  &.pill {
    &:hover {
      &:after {
        border-radius: 999px; } } }
  &.rt-rounded {
    &:hover {
      &:after {
        border-radius: 10px; } } } }

.rt-gradient2 {
  background-image: linear-gradient( -102deg, rgb(130,116,232) 0%, rgb(198,121,208) 100%);
  color: #fff;
  border: none;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background-image: $gradient-primary;
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff;
    border-radius: 10px; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } }
  &.pill {
    &:hover {
      &:after {
        border-radius: 999px; } } }
  &.rt-rounded {
    &:hover {
      &:after {
        border-radius: 10px; } } } }



.rt-gradient3 {
  background-image: linear-gradient( 0deg, rgb(255,170,87) 0%, rgb(254,92,118) 100%);
  color: #fff;
  border: none;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background-image: linear-gradient( -90deg, rgb(255,170,87) 0%, rgb(254,92,118) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff;
    border-radius: 10px; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } }
  &.pill {
    &:hover {
      &:after {
        border-radius: 999px; } } }
  &.rt-rounded {
    &:hover {
      &:after {
        border-radius: 10px; } } } }



.rt-outline-gradientL {
  background: transparent;
  color: rgba(90, 90, 90, 0.5);
  border: 1px solid rgba(90, 90, 90, 0.5);
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: $gradient-primary;
    opacity: 0;
    visibility: hidden;
    transition: all 0.24s $ease-in-sine;
    color: #fff; }
  &:hover {
    color: #fff;
    border: 1px solid transparent;
    &:after {
      opacity: 1;
      visibility: visible; } }
  &.pill {
    &:hover {
      &:after {
        border-radius: 999px; } } } }

.rt-outline-gradientP {
  background: transparent;
  color: #333;
  border-radius: 3px;
  border: 1px solid $primary-color;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: linear-gradient(to right, $primary-color, $secondary-color);
    opacity: 0;
    visibility: hidden;
    transition: all 0.24s $ease-in-sine;
    color: #333; }
  &:hover {
    color: #fff;
    border: 1px solid transparent;
    &:after {
      opacity: 1;
      visibility: visible; } } }
.rt-outline-gradientS {
  background: transparent;
  color: #333;
  border-radius: 3px;
  border: 1px solid $secondary-color;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: linear-gradient(to right, $secondary-color, $primary-color);
    opacity: 0;
    visibility: hidden;
    transition: all 0.24s $ease-in-sine;
    color: #333; }
  &:hover {
    color: #fff;
    border: 1px solid transparent;
    &:after {
      opacity: 1;
      visibility: visible; } } }



.rt-outline-primary:not(:disabled):not(.disabled).active, .rt-outline-primary:not(:disabled):not(.disabled):active, .show>.rt-outline-primary.dropdown-toggle {
  background: $primary-color;
  border-color: $primary-color;
  box-shadow: none; }

.rt-outline-primary:not(:disabled):not(.disabled).active:focus, .rt-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.rt-outline-primary.dropdown-toggle:focus {
  box-shadow: none; }



.aps-button-gruop {
  .rt {
    margin: 0 5px;
    &:first-child {
      margin-left: -5px; } } }

// rt linbk
.rt-lnk {
  text-decoration: underline;
  color: $body-font-colr; }
.vp-pagination__style-default .vp-pagination__item>* {
  @extend .rt;
  @extend .rt-secondary;
  border-radius: 999px; }
.vp-filter__style-default .vp-filter__item.vp-filter__item-active>* {
  @extend .rt-secondary;
  border-radius: 3px; }



// app button
.rt-app-parimary {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 40px;
  .app-thumb {
    @include flex-width(0px);
    margin-right: 30px;
    i {
      font-size: 20px; } }
  .app-text {
    //+flex-width(40%)
    span {
      display: block;
      font-weight: 600;
      font-family: $body-font;
      text-transform: uppercase;
      &:nth-child(1) {
        font-size: 12px; }
      &:nth-child(2) {
        font-size: 16px; } } }

  background: linear-gradient( 35deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%);
  color: #fff;
  border: none;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: linear-gradient( -35deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff;
    border-radius: 10px; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } } }
.rt-app-secondary {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 40px;
  .app-thumb {
    @include flex-width(0px);
    margin-right: 30px;
    i {
      font-size: 25px; } }
  .app-text {
    //+flex-width(40%)
    span {
      display: block;
      font-weight: 600;
      font-family: $body-font;
      text-transform: uppercase;
      &:nth-child(1) {
        font-size: 12px; }
      &:nth-child(2) {
        font-size: 16px; } } }

  background: linear-gradient( 35deg, rgb(1,223,184) 0%, rgb(73,229,65) 100%);
  color: #fff;
  border: none;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: linear-gradient( 35deg, rgb(73,229,65) 0%, rgb(1,223,184) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff;
    border-radius: 10px; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } } }




