.rt-form {
    #{$all-text-inputs}, .form-control {

        min-height: 51px;
        display: block;
        width: 100%;
        padding: 20px;
        border: 1px solid rgba(90, 90, 90, 0.5);
        font-size: 16px;
        &.pill {
            border-radius: 999px; }
        &::placeholder {
            color: #727799;
            font-size: 16px; }
        &:focus {
            outline: none;
            border-color: $primary-color;
            box-shadow: none; } }

    select {
        width: 100%;
        height: 51px;
        border: 1px solid rgba(90, 90, 90, 0.5);
        background-color: transparent;
        color: #727799;
        &:focus {
            outline: none; } }


    #{$all-text-inputs-focus},.form-control:focus {
        box-shadow: none !important;
        border-color: $primary-color !important;
        background: transparent;
        outline: none; }
    #{$all-text-inputs-hover},.form-control:hover {
        box-shadow: none !important;
        border-color: $primary-color !important;
        background: transparent; }
    .forgot-pass label {
        padding-left: 10px;
        font-size: 14px; }
    &.rt-line-form {
        #{$all-text-inputs}, .form-control {
            border: none;
            border-bottom: 1px solid rgba(90, 90, 90, 0.5);
            background: transparent; }
        #{$all-text-inputs-focus},.form-control:focus,
        #{$all-text-inputs-hover},.form-control:hover {
            outline: none;
            border-color: $primary-color !important;
            box-shadow: none; }


        select {
            width: 100%;
            border: none;
            border-bottom: 1px solid rgba(90, 90, 90, 0.5); } }
    .submit-circle {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
    &.light-form {
        #{$all-text-inputs}, .form-control {
            border-color: #c0bdbc;
            color: #fff;
            &::placeholder {
                color: #c0bdbc; } }
        #{$all-text-inputs-focus},.form-control:focus,
        #{$all-text-inputs-hover},.form-control:hover {
            border-color: #fff !important; } } }



