.bg-elements-parent {
    position: relative; }

.rt-bg-elemtnts {
    position: absolute;
    &.rt-shape-ani-1 {
        right: 106px;
        height: 142px;
        width: 143px;
        top: 106px;
        @include breakpoint(xl) {
            right: 70px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 7s linear infinite; } }
    &.rt-shape-ani-2 {
        right: 268px;
        height: 71px;
        width: 71px;
        top: 350px;
        @include breakpoint(xl) {
            right: 165px; }
        @include breakpoint(lg) {
            display: none; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin2 4s linear infinite; } }
    &.rt-shape-ani-3 {
        right: 122px;
        height: 35px;
        width: 35px;
        top: 320px;
        @include breakpoint(xl) {
            right: 40px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 10s linear infinite; } }
    &.rt-shape-ani-4 {
        right: 156px;
        height: 142px;
        width: 143px;
        top: 106px;
        @include breakpoint(xl) {
            right: 70px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 7s linear infinite; } }
    &.rt-shape-ani-5 {
        right: 312px;
        height: 71px;
        width: 71px;
        top: 350px;
        @include breakpoint(xl) {
            right: 165px; }
        @include breakpoint(lg) {
            display: none; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin2 4s linear infinite; } }
    &.rt-shape-ani-6 {
        right: 172px;
        height: 35px;
        width: 35px;
        top: 320px;
        @include breakpoint(xl) {
            right: 40px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 10s linear infinite; } }
    &.rt-shape-ani-7 {
        left: 100px;
        height: 70px;
        width: 70px;
        top: 100px;
        @include breakpoint(xl) {
            left: 50px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 10s linear infinite; } }
    &.rt-shape-ani-8 {
        left: 150px;
        height: 35px;
        width: 35px;
        top: 220px;
        @include breakpoint(xl) {
            left: 100px; }
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin2 10s linear infinite; } }
    &.rt-shape-ani-9 {
        left: 189px;
        height: 140px;
        width: 140px;
        top: 100px;
        @include breakpoint(lg) {
            display: none; }
        .spin-container {
            animation: spin 4s linear infinite; } }
    &.rt-shape-ani-10 {
        left: 141px;
        height: 70px;
        width: 70px;
        top: 300px;
        @include breakpoint(lg) {
            display: none; }
        .spin-container {
            animation: spin2 6s linear infinite; } }
    &.rt-shape-ani-12 {
        left: 300px;
        height: 70px;
        width: 70px;
        top: 600px;
        @include breakpoint(lg) {
            display: none; }
        .spin-container {
            animation: spin2 6s linear infinite; } }
    &.rt-shape-ani-11 {
        left: 240px;
        height: 35px;
        width: 35px;
        top: 480px;
        @include breakpoint(xl);
        @include breakpoint(md) {
            display: none; }
        .spin-container {
            animation: spin 3s linear infinite; } }


    &.shape-right {
        right: -100px;
        top: 50%;
        transform: translateY(-50%);
        height: 802px;
        width: 673px;
        @include breakpoint(xxl) {
            width: 600px; }
        @include breakpoint(xl) {
            width: 470px; }
        @include breakpoint(lg) {
            display: none; } }
    &.shape-right-2 {
        right: 150px;
        top: 150px;
        height: 686px;
        width: 474px;
        @include breakpoint(xxl) {
            right: 50px; }
        @include breakpoint(xl) {
            right: 0px;
            width: 400px; }
        @include breakpoint(lg) {
            display: none; } }
    &.shape-right-3 {
        right: 0px;
        top: -100px;
        height: 1031px;
        width: 945px;
        @include breakpoint(xxl) {
            width: 850px; }
        @include breakpoint(xl) {
            width: 650px; }
        @include breakpoint(lg) {
            display: none; } }
    &.shape-right-4 {
        right: 150px;
        top: 100px;
        height: 461px;
        width: 567px;
        @include breakpoint(xxl) {
            right: 50px; }

        @include breakpoint(xl) {
            right: 0px; }

        @include breakpoint(lg) {
            display: none; } }
    &.shape-left {
        left: 150px;
        top: 50%;
        transform: translateY(-50%);
        height: 342px;
        width: 500px;
        @include breakpoint(xxl) {
            left: 50px; }
        @include breakpoint(xl) {
            left: 0px; }
        @include breakpoint(lg) {
            display: none; } }
    &.row-img-right {
        right: 150px;
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint(xxl) {
            right: 100px; }
        @include breakpoint(xl) {
            right: 50px;
            img {
                height: 400px; } }
        @include breakpoint(lg) {
            right: 30px;
            img {
                height: 350px; } }
        @include breakpoint(lg) {
            display: none; } }
    &.row-img-left {
        left: 150px;
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint(xxl) {
            left: 100px; }
        @include breakpoint(xl) {
            left: 50px;
            img {
                height: 400px; } }
        @include breakpoint(lg) {
            left: 30px;
            img {
                height: 350px; } }
        @include breakpoint(lg) {
            display: none; } } }

.tab-navsitem {
    position: relative;
    top: 8px;
    .nav-1 {
        border: 1px solid rgba(222,222,222,.6);
        color: $body-font-colr;
        padding: 10px 30px;
        border-radius: 999px;
        font-size: 16px;
        font-weight: 300; }
    ul {
        flex-direction: column;
        li {
            display: block;
            a {
                font-size: 15px;
                color: $body-font-colr;
                &:hover {
                    color: $primary-color; } } } } }
.top-ss {
    box-shadow: 0px 0px 15px 0px rgba(29, 17, 128, 0.3);
    padding: 15px 30px; }

@import "shape-ani";
