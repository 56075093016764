.open-offcanvasmenu {
    cursor: pointer;
    span {
      display: block;
      background: #999;
      width: 30px;
      margin-bottom: 5px;
      height: 2px;
      transition: all 0.12s ease-in-out; }
    &:hover {
      span {
        background: $primary-color;
        &:nth-child(1) {
          margin-bottom: 8px; }
        &:nth-child(2) {
          position: relative;
          width: 20px;
          margin-left: 5px; }
        &:nth-child(3) {
          margin-top: 8px; } } } }

// offcanvash

.rt-sidnav {
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  width: 385px;
  z-index: 999;
  height: 100%;
  padding: 30px;
  box-shadow: 0px 24px 35px 0px rgba(153, 153, 153, 0.15);
  transition: all 0.4s ease-in-out;
  transform: translateX(385px);
  @media (max-width: 320px) {
    width: 300px !important; }
  &.active {
    transform: translateX(0px);
    animation: slideInRight 400ms ease-in-out; }
  .sidenav-close {
    color: $primary-color;
    cursor: pointer;
    font-size: 30px; } }
.rt-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.4);
  @include hidden();
  @include rt-trs(0.24s);
  &.active {
    z-index: 9;
    @include vis(); } }
