// typography
$heading-font: 'Open Sans', sans-serif;
$body-font: 'Open Sans', sans-serif;



// font size
$body-font-size: 16px;
// heading font size
$h1-font-size: 50px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 15px;
$heading-margin: 0 0 10px;
$heading-padding: 0 0 0px;


// default font size
$default-font-size: 16px;
$p-margin: 0 0 15px;
$p-padding: 0 0 0px;


// line height
$line-height: 26px;
$line-height-2: 1.7em;





// variables for color
$primary-color: #f30070;
$secondary-color: #ff6d00;
$custom-color1: #666666;
$custom-color2: #cccccc;
$custom-color3: #7fb1eb;
$custom-color4: #444444;
$custom-color5: #222222;
$custom-color6: #333333;
$link-color: #7fb1eb;
$white-color: #fff;

// gradient color
$gradient-primary: linear-gradient( 102deg, rgb(243,0,112) 0%, rgb(235,126,10) 100%);
$gradient-primary_reverse: linear-gradient( -102deg, rgb(243,0,112) 0%, rgb(235,126,10) 100%);


// font color
$body-font-colr: #2e3d62;
$headnig-font-color:  #2e3d62;


// quibick burzir animation
$ease-in-quad:      cubic-bezier(0.550,  0.085, 0.680, 0.530);
$ease-in-cubic:     cubic-bezier(0.550,  0.055, 0.675, 0.190);
$ease-in-quart:     cubic-bezier(0.895,  0.030, 0.685, 0.220);
$ease-in-quint:     cubic-bezier(0.755,  0.050, 0.855, 0.060);
$ease-in-sine:      cubic-bezier(0.470,  0.000, 0.745, 0.715);
$ease-in-expo:      cubic-bezier(0.950,  0.050, 0.795, 0.035);
$ease-in-circ:      cubic-bezier(0.600,  0.040, 0.980, 0.335);
$ease-in-back:      cubic-bezier(0.600, -0.280, 0.735, 0.045);

$ease-out-quad:     cubic-bezier(0.250,  0.460, 0.450, 0.940);
$ease-out-cubic:    cubic-bezier(0.215,  0.610, 0.355, 1.000);
$ease-out-quart:    cubic-bezier(0.165,  0.840, 0.440, 1.000);
$ease-out-quint:    cubic-bezier(0.230,  1.000, 0.320, 1.000);
$ease-out-sine:     cubic-bezier(0.390,  0.575, 0.565, 1.000);
$ease-out-expo:     cubic-bezier(0.190,  1.000, 0.220, 1.000);
$ease-out-circ:     cubic-bezier(0.075,  0.820, 0.165, 1.000);
$ease-out-back:     cubic-bezier(0.175,  0.885, 0.320, 1.275);

$ease-in-out-quad:  cubic-bezier(0.455,  0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645,  0.045, 0.355, 1.000);
$ease-in-out-quart: cubic-bezier(0.770,  0.000, 0.175, 1.000);
$ease-in-out-quint: cubic-bezier(0.860,  0.000, 0.070, 1.000);
$ease-in-out-sine:  cubic-bezier(0.445,  0.050, 0.550, 0.950);
$ease-in-out-expo:  cubic-bezier(1.000,  0.000, 0.000, 1.000);
$ease-in-out-circ:  cubic-bezier(0.785,  0.135, 0.150, 0.860);
$ease-in-out-back:  cubic-bezier(0.680, -0.550, 0.265, 1.550);




//social color
$icon-facebook: #3b5999;
$icon-twitter: #3AA8DB;
$icon-google-plus: #D04F3E;
$icon-behance: #1882FF;
$icon-dribbble: #E95AAE;
$icon-flickr: #E95AAE;
$icon-instagram: #4F86AC;
$icon-linkedin: #008BC2;
$icon-pinterest: #CB1F24;
$icon-skype: #00BEF4;
$icon-slack: #44BA97;
$icon-tumblr: #435971;
$icon-vimeo: #0BC4EF;
$icon-vine: #00BE9B;
$icon-youtube: #ED4533;




// menu settings
$menu_font_family: $body_font;
$menu_font_size: 16px;
$menu_item_color: #333;
$menu_item_hover_color: $secondary-color;
$menu_item_active_color: $secondary-color;


// submenu
$submenu_bg: #fff;
$submenu_item_color: $body-font-colr;
$submenu_border_radius: 3px;
$submenu_border: none;
$submenu_item_border_color: 1px solid #eee;
$submenu_hover_bg: $secondary-color;


// onepage menu
$onepage_menu_font: $body_font;
$onepage_menu_font_size: 16px;
$onepage_menu_font_color: #ffffff;
$onepage_menu_item_margin: 15px;


// one page submenu
$onepage_sub_menubg: #ffffff;
$onepage_sub_menushadow: 0 10px 10px 0 rgba(145, 145, 145, 0.1);
$onepage_sub_menu_border-radius: 5px;


// mobile menu setting
$mobile_menu_hambugrar_bg_color: $secondary-color;
$mobile_menu_hambugrar_border_color: $secondary-color;
$mobile_menu_hambugrar_bar_color: #fff;

$mobile_menu_bg_color: $secondary-color;
$mobile_menu_sub_menu_bg_color: $secondary-color;

$mobile_menu_item_color: #fff;
$mobile_menu_submenu_item_color: #fff;

$mobile_menu_active_coor: #ffffff;

$mobile_menu_item_hover_color: $secondary-color;
$mobile_menu_item_hover_bg_color: #fff;
$mobile_menu_submenu_hover_item_color: #fff;
$mobile_menu_submenu_hover_item_bg_color: $secondary-color;





// button setting
$button_font_family: $body_font;
$button_font_size: 16px;
$button_padding: 16px 37px;
$buton_border_radius: 0px;
$button_font_weight: 600;
$button_border_width: 0px;

$button-primary: #007bff;
$button-secondary: #6c757d;
$button-ssucess: #28a745;
$button-danger: #dc3545;
$button-warning: #ffc107;
$button-info: #17a2b8;
$button-light: #f8f9fa;
$button-dark: #343a40;
