.rt-price-1 {
    @include afterparent();
    .price-hrader {
        img {
            margin-bottom: 30px; } }
    .price-body {
        ul {
            li {
                box-shadow: 0px 5px 15px 0px rgba(28, 75, 154, 0.1);
                padding: 10px 15px;
                margin-bottom: 10px;
                color: #4228a8;
                @include rt-trs(0.34s);
                max-width: 268px;
                margin-left: auto;
                margin-right: auto;

                span {
                    color: #2e3d62; }
                &:last-child {
                    margin-bottom: 0px; }
                &:hover,&.active {
                    transform: scale(1.35);
                    box-shadow: 0px 5px 15px 0px rgba(28, 75, 154, 0.3);
                    font-size: 18px;
                    @include breakpoint(xs) {
                        transform: scale(1.1); } } } } }
    .price-footer {
        a {
            font-size: 18px;
            text-transform: capitalize;
            color: $body-font-colr;
            &:hover {
                color: $primary-color; } } } }

