// icon circle

@for $i from 0 through 200 {
	.rt-hw-#{$i} {
		height: $i + px;
		width: $i + px;
		line-height: $i + px; } }

.rt-circle {
  border-radius: 50%;
  &:after {
    border-radius: 50%; } }
.rt-icon {

  @include afterparent();
  text-align: center; }




.icon-gradinet-1 {
  background-image: linear-gradient( 90deg, rgba(#7040ff, 1) 0%, rgba(#5a33ff, 1) 100%);
  color: #fff;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background-image: linear-gradient( -90deg, rgba(#7040ff, 1) 0%, rgba(#5a33ff, 1) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff; }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      visibility: visible; } } }

.icon-dark {
  background: #1e375a;
  color: #fff !important;
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: $primary-color;
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff !important; }
  &:hover {
    color: #fff !important;
    i {
      animation: zoomIn 400ms ease-in-out; }
    &:after {
      opacity: 1;
      visibility: visible; } } }
.icon-white-primary {
  background: #fff;
  color: rgb(119, 123, 146) !important;
  box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.1);
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: $primary-color;
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff !important; }
  &:hover {
    color: #fff !important;
    box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.0);
    i {
      animation: zoomIn 400ms ease-in-out; }
    &:after {
      opacity: 1;
      visibility: visible; } } }
.icon-white-secondary {
  background: #fff;
  color: rgb(119, 123, 146) !important;
  box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.1);
  @include afterparent();
  &:after {
    @include after(absolute, 0,0, 100%, 100%);
    z-index: -1;
    background: $secondary-color;
    opacity: 0;
    visibility: hidden;
    transition: all 0.36s $ease-in-sine;
    color: #fff !important; }
  &:hover {
    color: #fff !important;
    box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.0);
    i {
      animation: zoomIn 400ms ease-in-out; }
    &:after {
      opacity: 1;
      visibility: visible; } } }
// icon glow
.icon-glow-1 {
    box-shadow: 0 0 0 0px rgba(#985ddd, 0.7);
    animation: pulseservices2 0.9s infinite ease-in-out; }
.icon-glow-2 {
  box-shadow: 0 0 0 12px rgba(#7e5dfe, 0.15); }
@-webkit-keyframes pulseservices2 {
  to {
    box-shadow: 0 0 0 15px rgba(#985ddd, 0); } }



