.spacer-top {
  height: 120px;
  @include breakpoint(xs) {
    height: 70px; } }
.spacer-bottom {
  height: 85px;
  @include breakpoint(xs) {
    height: 40px; } }
.section-title-spacer {
  height: 40px; }
.rt-margin-top {
  margin-top: 120px;
  @include breakpoint(xs) {
    margin-top: 70px; } }
.rt-section-padding {
  padding: 120px 0;
  @include breakpoint(xs) {
    padding: 70px 0; } }


//-----------------------------
@for $i from 0 through 300 {
	.rt-spacer-#{$i} {
		height: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 300 {
		.rt-spacer-lg-#{$i} {
			height: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 300 {
		.rt-spacer-md-#{$i} {
			height: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 300 {
		.rt-spacer-xs-#{$i} {
			height: $i + px; } } }
//-----------------------------
@for $i from 0 through 200 {
	.rt-mb-#{$i} {
		margin-bottom: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-mb-lg-#{$i} {
			margin-bottom: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-mb-md-#{$i} {
			margin-bottom: $i + px; } } }

@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-mb-xs-#{$i} {
			margin-bottom: $i + px; } } }


//-----------------------------
@for $i from 0 through 200 {
	.rt-mt-#{$i} {
		margin-top: $i + px; } }

@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-mt-lg-#{$i} {
			margin-top: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-mt-md-#{$i} {
			margin-top: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-mt-xs-#{$i} {
			margin-top: $i + px; } } }
//-----------------------------
@for $i from 0 through 200 {
	.rt-ml-#{$i} {
		margin-left: $i + px; } }

@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-ml-lg-#{$i} {
			margin-left: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-ml-md-#{$i} {
			margin-left: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-ml-xs-#{$i} {
			margin-left: $i + px; } } }
//-----------------------------
@for $i from 0 through 200 {
	.rt-mr-#{$i} {
		margin-right: $i + px; } }

@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-mr-lg-#{$i} {
			margin-right: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-mr-md-#{$i} {
			margin-right: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-mr-xs-#{$i} {
			margin-right: $i + px; } } }
//-----------------------------
@for $i from 0 through 300 {
	.rt-pb-#{$i} {
		padding-bottom: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 300 {
		.rt-pb-lg-#{$i} {
			padding-bottom: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 300 {
		.rt-pb-md-#{$i} {
			padding-bottom: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 300 {
		.rt-pb-xs-#{$i} {
			padding-bottom: $i + px; } } }
//-----------------------------
@for $i from 0 through 300 {
	.rt-pt-#{$i} {
		padding-top: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 300 {
		.rt-pt-lg-#{$i} {
			padding-top: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 300 {
		.rt-pt-md-#{$i} {
			padding-top: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 300 {
		.rt-pt-xs-#{$i} {
			padding-top: $i + px; } } }


@for $i from 0 through 200 {
	.rt-p-#{$i} {
		padding: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-p-lg-#{$i} {
			padding: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-p-md-#{$i} {
			padding: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-p-xs-#{$i} {
			padding: $i + px; } } }
//-----------------------------
@for $i from 0 through 200 {
	.rt-pl-#{$i} {
		padding-left: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-pl-lg-#{$i} {
			padding-left: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-pl-md-#{$i} {
			padding-left: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-pl-xs-#{$i} {
			padding-left: $i + px; } } }
//-----------------------------
@for $i from 0 through 200 {
	.rt-pr-#{$i} {
		padding-right: $i + px; } }
@media (max-width: 1199.98px) {
	@for $i from 0 through 100 {
		.rt-pr-lg-#{$i} {
			padding-right: $i + px; } } }
@media (max-width: 991.98px) {
	@for $i from 0 through 100 {
		.rt-pr-md-#{$i} {
			padding-right: $i + px; } } }
@media (max-width: 767.98px) {
	@for $i from 0 through 100 {
		.rt-pr-xs-#{$i} {
			padding-right: $i + px; } } }
//-----------------------------
@for $i from 0 through 100 {
	.rt-rounded-#{$i} {
		border-radius: $i + px; } }

//-----------------------------


