.single-blg-post {
    position: relative;
    margin-bottom: 30px;

    .post-thumbnail {
        display: block;
        img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px; } }
    .entry-content {
        border: 1px solid  rgb(230, 230, 230);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: {
            left: 50px;
            top: 23px;
            bottom: 30px;
            right: 30px; }

        .entry-header {
            .entry-title {
                font-size: 24px;
                color: #132150;
                line-height: 36px;
                word-break: break-word;
                font-weight: 600;
                margin: 0 0 10px;
                transition: all 0.24s ease-in-out;
                a {
                    color: #132150;
                    &:hover {
                        color: $secondary-color; } } }
            .entry-meta {
                margin-bottom:  5px;
                span {
                    display: inline-block;
                    margin-right: 27px;
                    font-size: 14px;
                    a {
                        color: #c0c0c0;
                        transition: all 0.24s ease-in-out;
                        i {
                            color: #31348c;
                            padding-right: 5px; }
                        &:hover {
                            color: $secondary-color;
                            i {
                                color: $secondary-color; } } }
                    &:last-child {
                        margin-right: 0px; } } } }
        p {
            font-size: 16px;
            color: #717584;
            line-height: 26px; }
        .entry-footer {
            margin-top: 22px; } } }


