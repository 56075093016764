#accordion {
  margin-top: 30px;
  .card-header.card-primary {
    background: transparent;
    padding: 0px;
    border: none; }
  .card {
    margin-bottom: 15px;
    border-color: transparent;
    &:last-child {
      margin-bottom: 0px; } }
  .card-body {
    padding: {
      left: 20px;
      top: 15px;
      bottom: 20px;
      right: 20px; }
    transition: all 0.24s ease-in-out;
    color: $body-font-colr;
    border: 1px solid rgb(187, 187, 187);
    border-top: none;
    line-height: 36px;
    font-size: 24px;
    font-weight: 300;
    @include breakpoint(xs) {
      font-size: 16px;
      line-height: 24px; } } }
.card .card-header.card-primary button[aria-expanded="false"],
.card .card-header.card-primary button[aria-expanded="true"] {

  position: relative;
  display: block;
  text-align: left;
  background: transparent;
  padding: 16px 26px;
  @include breakpoint(xs) {
    padding: 16px 45px; }
  border-radius: 0px;
  font-size: 24px;
  font-weight: 400;
  color: $body-font-colr;
  border: 1px solid rgb(187, 187, 187);
  @include breakpoint(xs) {
    font-size: 20px; }
  text-decoration: none; }
.card .card-header.card-primary button[aria-expanded="true"] {
  background: transpaent;
  color: $body-font-colr;
  border-radius: 0px;
  border: 1px solid rgb(187, 187, 187);
  border-bottom: none; }






.card .card-header.card-primary button[aria-expanded="false"]:before {
  position: absolute;
  right: 15px;
  content: '\efc2';
  font-family: icofont;
  font-size: 16px;
  @include hw(40px);
  background-color:  rgb(126, 93, 254);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  @include breakpoint(xs) {
    @include hw(30px);
    right: 10px; } }

.card .card-header.card-primary button[aria-expanded="true"]:before {
  position: absolute;
  right: 15px;
  content: '\ef9a';
  font-family: icofont;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  @include hw(40px);
  @include breakpoint(xs) {
    @include hw(30px);
    right: 10px; }
  background-color:  rgb(126, 93, 254); }
.card .card-header.card-primary button {
  display: block;
  width: 100%;
  border: none;
  &:after {
    border-radius: 5px; }
  &:hover:after {
    border-radius: 5px; } }



