.select1_inner {
	position: relative;
	background: #fff;
	border: 1px solid  transparent;
	position: relative;
}

.select1_inner:after {
	content: '';
	display: block;
	width: 10px;
	height: 7px;
	background: url(../images/a1.png) 0 0 no-repeat;
	position: absolute;
	right: 10px;
	top: 17px;
}

.select2 {
	width: 100%;
}

.select2 *:focus {
	outline: none!important;
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: 500px;
	overflow: hidden;
}

.select2-container--default .select2-selection--single {
	background: none;

    
    
    position: relative;
    font-size: 14px;
}

 .select2-container--default .select2-selection--single {
	border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 14px;
    color: #fff;
    
	
}

.select2_inner .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-left: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	display: none;
}

.select2-dropdown {
	background: none;
	border-radius: 0;
	z-index: 10;
	min-width: 0px;
}

.select2-container--open .select2-dropdown--below {
	border: none;
}

.select2-results__option {
	padding: 10px 5px 10px 15px;
	background: $primary-color;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	border-top: 1px solid $primary-color;
}

.select2-results__option:first-child {
	border-top: none;
}

.small .select2-results__option {
	padding-left: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background: #fff;
	color: $primary-color;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background: #fff;
	color: $primary-color;
}





// range slider
.rt-widget{

.ui-slider{
	position: relative;
}
	.ui-widget.ui-widget-content {
  background: #eee;
  border: medium none;
  border-radius: 0px;
  height: 3px; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button {
  background: linear-gradient( 90deg, rgb(153,50,245) 0%, rgb(73,104,253) 100%);
  border: medium none; 
  height: 20px;
  width: 20px;
  border-radius: 0;
}

html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: #000 none repeat scroll 0 0;
  border: medium none;
  border-radius: 100%; }
.ui-widget-header{
  background:#3681ff;
}
.ui-slider-range.ui-corner-all.ui-widget-header {
  background: pink; }

.price_slider_amount {
  margin-top: 30px; 

	margin-bottom: 20px;
}
  .price_slider_amount input {
    display: inline-block; }

.ui-slider-horizontal .ui-slider-handle {
  top: -9px; 
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0.5px 0.866px 7px 3px rgba(73, 107, 254, 0.75);
  &:focus{
	  outline: none;
  }

}

.price_slider_amount input#amount {
  border: medium none;
	width: 100px;
  &:focus{
	  border: none;

	  outline: none;
  }
  }
  
  

.ui-slider-horizontal .ui-slider-range{
  height: 100%;
  top: 0px;
}



}



.tippy-tooltip{
	font-family: $body-font !important;
	padding: 8px 20px !important;
	font-size: 14px !important;
}

.ui-datepicker{

	.ui-datepicker-header{
		background: $gradient-primary;
		color: #fff;
	}

	.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
		background: $gradient-primary;
		border: 1px solid $primary-color;
		color: #fff;
	}
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active{
		background: $gradient-primary;
		border: 1px solid $primary-color;
		opacity: 0.7;
	}
}



