.rt-single-icon-box {
    display: flex;
    @include afterparent();
    background-color: rgb(255, 255, 255);
    align-items: start;
    @include rt-trs(0.24s);
    .icon-thumb {
        @include flex-width(65px);
        height: 58px;
        margin-right: 30px;
        img {
            @include rt-trs(0.24s); }
        i {
            font-size: 30px;
            color: $primary-color; } }

    &.icon-center {
        display: block;
        .icon-thumb {
            @include flex-width(100%);
            height: auto;
            margin-right: 0px;
            margin-bottom: 35px;
            &.thumb_1 {
                margin-bottom: 15px; } } }
    .iconbox-content {
        a {
            color: $body-font-colr;
            font-size: 18px; } }

    &.shdoaw-style {
        box-shadow: 0px 15px 30px 0px rgba(68, 86, 103, 0.2);
        background-color: #fff;
        border-color: transparent; }
    &.shdoaw-style2 {
        box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
        background-color: #fff;
        border-color: transparent; }
    &.shdoaw-style3 {
        box-shadow: 0px 15px 40px 0px rgba(68, 86, 103, 0.3);
        background-color: #fff;
        border-color: transparent; }
    &.border-style-1 {
       border: 1px solid rgb(188, 188, 188); }







    &:hover {
        border-color: transparent;
        &.shdoaw-style {
            box-shadow: 0px 15px 30px 0px rgba(68, 86, 103, 0.2); }
        &.shdoaw-style2 {
            box-shadow: 0px 10px 20px 0px rgba(46, 61, 98, 0.1); }
        &.shdoaw-style3 {
            box-shadow: 0px 15px 40px 0px rgba(68, 86, 103, 0.1); }
        transform: translateY(-5px);
        &.border-style-1 {
            box-shadow: 0px 5px 30px 0px rgba(189, 189, 192, 0.3);
            border: 1px solid rgba(188, 188, 188, 0.3);
            background-color: #fff; }
        .iconbox-content {
            a {
                color: $secondary-color; } }
        .icon-thumb {
            img {
                animation: rotate-in-2-fwd .6s cubic-bezier(.25,.46,.45,.94) both; } } } }





// chose slider
.active-choose {
    .owl-item {
        &.center {
            .rt-single-icon-box {
                top: -69px;
                box-shadow: 0px 15px 40px 0px rgba(68, 86, 103, 0.2);
                .iconbox-content {
                    a {
                        color: $secondary-color; } } } } } }
.active-choose {

    .owl-nav .owl-prev, .owl-nav .owl-next {
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        @include hw(70px);
        background-color: #fff !important;
        text-align: center;
        border-radius: 50%;
        color: $body-font-colr !important;
        font-size: 30px !important;
        box-shadow: 0px 5px 15px 0px rgba(67, 36, 217, 0.05);
        @include breakpoint(lg) {
          @include hw(45px);
          font-size: 22px !important; }
        &:after {
            @include after(absolute, 0, 0, 100%, 100%);
            background: linear-gradient( 28deg, rgb(126,93,254) 0%, rgb(190,123,244) 100%);
            z-index: -1;
            border-radius: 50%;
            @include hidden(); }
        &:hover {
            color: #fff !important;
            &:after {
                @include vis();
                animation: zoomIn 400ms ease-in-out; } }


        &:focus {
            outline: none; } }
    .owl-nav .owl-next {
        margin-left: 100px;
        @include breakpoint(lg) {
          margin-left: 50px; } } }



.box-down {
    top: 40px; }
