.rt-social {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        display: inline-block;
        padding-right: 10px;
        @include breakpoint(xs) {
          padding-right: 8px; }
        @include afterparent();
        &:last-child {
            padding-right: 0px; }
        a {
            display: block;
            color: #3d446f;
            @include rt-trs(0.34s);
            @include afterparent();
            &:hover {
                color: $primary-color; } } }
    &.normal-style-one {
        li {
            color: #b3b7c8;
            padding-right: 30px;
            @include breakpoint(md) {
                padding-right: 20px; }
            @include breakpoint(xs) {
                padding-right: 8px; }
            &:last-child {
                padding-right: 0px; }
            a {
                color: #b3b7c8;
                &:hover {
                    color: $primary-color; } } } }
    &.rt-circle-style {
        li {
            a {
                @include hw(30px);
                text-align: center;
                border: 1px solid #333;
                border-radius: 50%;
                font-size: 14px;
                &:after {
                    @include after(absolute, 0, 0, 100%, 100%);
                    z-index: -1;
                    background: linear-gradient( 8deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%);
                    border-radius: 50%;
                    @include rt-trs(0.34s);
                    @include hidden(); }
                &:hover {
                    color: #fff !important;
                    border: transparent;
                    &:after {
                        @include vis();
                        animation: zoomIn 0.34s ease-in-out; } } } } }
    &.rt-circle-style2 {
        li {
            a {
                @include hw(43px);
                text-align: center;
                border: 1px solid rgba(#fff , 0.8);
                border-radius: 50%;
                font-size: 16px;
                box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.4);
                color: #496cfe;
                background-color: #fff;
                &:hover {
                    color: #fff !important;
                    background: #ff8665;
                    i {
                        animation: zoomIn 400ms ease-in-out; } } } } } }





