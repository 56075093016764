
html,body {
  height: 100%; }
body {
  line-height: $line-height;
  font-size: $body-font-size;
  color: $body-font-colr;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  overflow-x: hidden;
  counter-reset: my-sec-counter; }




a {
  @include rt-trs(0.24s);
  &:hover {
    text-decoration: none; }
  &.link-text {
    color: $body-font-colr; } }





img {
  max-width: 100%; }
.owl-item img {
  width: auto !important;
  margin-left: auto;
  margin-right: auto; }

.rt-list {
  margin: 0;
  padding: 0;
  list-style: none; }
button.mfp-close {
  background: #fff;
  color: $primary-color !important;
  opacity: 1;
  font-size: 20px;
  height: 35px;
  width: 35px !important;
  border-radius: 50%;
  text-align: center !important;
  line-height: 35px !important;
  padding: 0 !important;
  position: absolute;
  top: -52px !important; }

img.mfp-img {
  padding: 0px !important;
  border: 6px solid #fff;
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important; }

.mfp-counter {
  right: 15px;
  color: #fff;
  font-size: 14px;
  background: $secondary-color;
  padding: 3px 16px;
  border-radius: 3px;
  font-weight: 700; }
.mfp-content {
  border: 6px solid #fff;
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important; }

.smallgap.row>[class*=col-] {
  padding-left: 3px !important;
  padding-right: 3px !important; }



// badge
.site-badge {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding: 0 1rem;
  border-radius: 2px;
  &.has-arrow-primary {
    &:after {
      @include triangle("down", 2rem, 1rem, $primary-color);
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      @include breakpoint(md) {
        bottom: -8px; }
      @include breakpoint(xs) {
        bottom: -5px; } } }
  &.has-arrow-secondary {
    &:after {
      @include triangle("down", 2rem, 1rem, $secondary-color);
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px; } } }
.s-badge-primary {
  background: $primary-color;
  color: $secondary-color; }

.rt-pill {
  border-radius: 999px; }
.rt-border-primary {
  border: 1px solid $primary-color; }
.rt-border-primary2 {
  border: 1px solid rgba($primary-color, 0.5); }
.rt-border-secondary {
  border: 1px solid $secondary-color; }
.rt-dborder-primary {
  border: 1px dashed $primary-color; }
.rt-dotted-primary {
  border: 1px dotted $primary-color; }
.rt-dorder-off {
  border: 1px dashed rgb(175, 175, 175); }
.rt-border-off2 {
  border: 1px solid #c0bdbc; }




#scrollUp {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 45px;
  height: 45px;
  line-height: 48px;
  text-align: center;
  background: $gradient-primary;
  color: #fff;
  font-size: 30px;
  border-radius: 50%; }

.review {
  color: #ffab53; }


blockquote {
  background: linear-gradient( 8deg, rgb(153,50,245) 0%, rgb(73,51,241) 53%, rgb(73,80,248) 70%, rgb(73,108,254) 100%);
  font-size: 17px;
  font-style: italic;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  padding: {
    left: 20px;
    top: 40px;
    right: 20px;
    bottom: 20px; }
  position: relative;
  &:after {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    content: '\efcd';
    font-family: icofont;
    color:  rgba(228, 222, 255, 0.2);
    font-size: 80px; } }



// section padding



// overlay
.rt-inner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.rtbgprefix-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.rtbgprefix-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
.rtbgprefix-full {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center; }
.rtbg-paralax {
  background-attachment: fixed; }


// divider
.rt-divider {
  height: 1px;
  width: 100%;
  &.style-one {
    background-color: rgba(3, 64, 131, 0.2); }
  &.style-two {
    background-color: rgba(208, 230, 255, 0.20); }
  &.style-three {
    background-color: rgba(73, 108, 254, 1); } }

// shadow
.rt-Bshadow-1 {
  box-shadow: 0px 10px 10px 0px rgba(28, 75, 154, 0.3);
  &:hover {
    box-shadow: 0px 10px 10px 0px rgba(28, 75, 154, 0.1); } }

.rt-Bshadow-2 {
  box-shadow: 0px 15px 30px 0px rgba(13, 21, 75, 0.2);
  &:hover {
    box-shadow: 0px 15px 30px 0px rgba(13, 21, 75, 0.1); } }
.rt-Bshadow-3 {
  box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.4);
  &:hover {
    box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.2); } }
.rt-Bshadow-4 {
  box-shadow: 0px 5px 20px 0px rgba(13, 21, 75, 0.2);
  &:hover {
    box-shadow: 0px 13px 20px 0px rgba(13, 21, 75, 0.4); } }

.rt-Bshadow-3 {
  box-shadow: 0px 15px 30px 0px rgba(#ab72b9, 0.2);
  &:hover {
    box-shadow: 0px 15px 30px 0px rgba(#ab72b9, 0.1); } }

.rt-shadow-1 {
  box-shadow: 0px 5px 10px 0px rgba(27, 3, 132, 0.5); }


.domain-border {
  border-right: 1px solid rgb(178, 178, 178); }

// box style
.rt-box-style-1 {
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(27, 3, 132, 0.5);
  background-color: rgb(255, 255, 255);
  padding: 30px; }
.rt-box-style-2 {
  box-shadow: 0px 5px 15px 0px rgba(28, 75, 154, 0.1);
  background-color: rgb(255, 255, 255);
  padding: 30px; }
.rt-box-style-3 {
  box-shadow: 0px 5px 15px 0px rgba(28, 75, 154, 0.1);
  background-color: rgb(255, 255, 255); }



body {
  @include breakpoint(lg) {
    .bg-hide-lg {
      background-image: none !important; }
    .gradinet-bg-lg {
      background-image: $gradient-primary !important; } }
  @include breakpoint(md) {
    .bg-hide-md {
      background-image: none !important; }
    .gradinet-bg-md {
      background-image: $gradient-primary !important; } } }









@import "margin-padding";

