.counter-box-1 {
    background-color: #fff;
    img {
        margin-right: 40px; }
    h5 {
        font-family: $body-font;
        font-size: 18px;
        margin: 0 0 0px; }
    h6 {
        font-size: 40px;
        font-weight: 400;
        margin: 0 0 15px; }
    &.has-border-right {
        border-right: 1px solid rgba(#000, 0.40); }
    &:hover {
        img {
            animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; } } }


// ciounter box 2
.counter-box-2 {
    @include afterparent();
    margin-bottom: 30px;
    .counter-thumb {
        margin-bottom: 20px; }
    h5 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 40px; }
    h6 {
        font-size: 14px;
        font-family: $body-font;
        color: #3d446f;
        margin: 0 0 0;
        font-weight: 400; }
    &:hover {
        .counter-thumb {
            img {
                animation: pulse 400ms  ease-in-out; } } } }
// icon box
@import "icon-box";
