
.spin-container {
    width: 100%;
    height: 100%;
    
    position: relative;
}

.shape {
    width: 100%;
    height: 100%;
    transition: border-radius 1s ease-out;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    animation: morph 8s ease-in-out infinite both alternate;
    position: absolute;
    overflow: hidden;
    z-index: 5;
}

.bd {
    width: 142%;
    height: 142%;
    position: absolute;
    left: -21%;
    top: -21%;
   
    background-size: 100%;
    background-position: center center;
    display: flex;
    color: #003;
    font-size: 5vw;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    animation: spin 12s linear infinite reverse;
    opacity: 1;
    z-index: 2;
    opacity: 0.20;
    &.border_bg-1{
         background: linear-gradient(-102deg, rgb(75, 201, 254) 0%, rgb(80, 82, 254) 100%);
    }
    &.border_bg-2   {
         background: linear-gradient( -102deg, rgb(172,239,233) 0%, rgb(55,197,178) 100%);
    }
    &.border_bg-3   {
         background: linear-gradient(-102deg, rgb(235, 126, 10) 0%, rgb(243, 0, 112) 100%);
    }
}

@keyframes morph {
    0% {
        border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
    }

    100% {
        border-radius: 40% 60%;
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}
@keyframes spin2 {
    to {
        transform: rotate(-1turn);
    }
}