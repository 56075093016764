.singleTbox-1 {
    @include afterparent();
    .testi-thumb {
        height: 260px;
        width: 260px;
        @include afterparent();
        box-sizing: border-box;
        border-radius: 50%;
        background: linear-gradient( 0deg, rgb(157,91,254) 0%, rgb(56,144,254) 100%);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
        @include breakpoint(lg) {
          height: 160px;
          width: 160px; }
        .inner-bg {
            position: absolute;
            left: 6px;
            top: 6px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 248px;
            height: 248px;
            border-radius: 50%;
            box-shadow: 0px 17px 20px 0px rgba(25, 80, 161, 0.35);
            @include breakpoint(lg) {
              width: 190px;
              height: 190px;
              left: 5px;
              top: 5px; }
            @include breakpoint(xs) {
              width: 150px;
              height: 150px;
              left: 5px;
              top: 5px; } }
        .social-badge {
            @include hw(60px);
            background: linear-gradient( 35deg, rgb(157,91,254) 0%, rgb(56,144,254) 100%);
            color: #ffffff;
            position: absolute;
            right: 20px;
            top: 0;
            border-radius: 50%;
            @include breakpoint(lg) {
              @include hw(40px); } } }
    .autor-bio {
        margin-bottom: 30px;
        h5 {
            font-size: 22px;
            font-weight: 700;
            color: #333;
            text-transform: capitalize; }

        p {
            font-size: 14px; }
        .rating {
            color: #ffab53;
            font-size: 14px; } }
    .inner-content {
        p {
            font-size: 18px;
            line-height: 28px; } } }





.testimonials-area {
    @include afterparent();
    .rt-shape-emenetns-1 {
      position: absolute;
      right: -200px;
      bottom: 100px;
      width: 522px;
      height: 558px;
      @extend .rtbgprefix-contain;
      @include breakpoint(xl) {
        @include hw(400px); }
      @include breakpoint(md) {
        @include hw(200px);
        right: -100px; }
      @include breakpoint(xs) {
        display: none; } } }

.rt-light-text {
    .singleTbox-1 {
        .autor-bio {
            p {
                color: #fff; }
            h5 {
                color: #fff; } }
        .inner-content {
            p {
                color: #fff; } } } }


@import "round-testi";


.rt-duel-slider-main {
  .slick-list {
    padding: 30px 0;
    box-sizing: initial; }
  .single-testimonils {
    //box-shadow: 0px 5px 10px 0px rgba(27, 3, 132, 0.5)
    margin-left: 30px;
    margin-right: 30px !important;
    @include afterparent();
    background-image: url(../images/logo/tbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center; } }

.rt-duel-slider-thumb {

    padding-left: 80px;
    padding-right: 80px {

      padding: 35px; }
    @include breakpoint(lg) {
        padding-left: 40px;
        padding-right: 40px; }
    @include breakpoint(xs) {
        padding-right: 15px;
        padding-left: 15px; }
    .single--thumb {
        margin-right: 10px;
        .inner-content {
          height: 90px;
          width: 90px;
          border-radius: 50%; }
        border: 1px solid transparent;
        @include prefixer(appearance, none, ("webkit", "moz"));
        outline: none;
        &:last-child {
            margin-right: 0px; } }

    .arrow_left, .arrow_right {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        color: #333;
        font-size: 25px;
        z-index: 66;
        cursor: pointer;
        opacity: 1;
        &:focus {
            outline: none; }
        @include breakpoint(xs) {
          @include hw(35px);
          background-color: $primary-color;
          color: #fff;
          left: 0;
          border-radius: 5px; } }


    .arrow_right {
        left: auto;
        right: 15px; } }

.rt-duel-slider-thumb .single--thumb.slick-center .out-border {
  border: 2px dashed #906afc;
  height: 130px;
  width: 130px;
  border-radius: 50%; }
.rt-duel-slider-thumb .single--thumb.slick-center .inner-content {
  border: 3px solid #906afc; }
.rt-duel-slider-thumb .single--thumb .inner-content {
  position: relative;
  top: 18px; }




.single-testimonils h4.secondary-color {
  color: #906afc !important; }
