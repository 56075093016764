.rt-alrt-1 {
    @include afterparent();
    box-shadow: 0px 4px 20px 0px rgba(46, 70, 198, 0.2);
    color: #555555;
    &:after {
        @include after(absolute, 0, 0, 100%, 100%);
        z-index: -1;
        background-image: linear-gradient( 8deg, rgb(73,108,254) 0%, rgb(73,80,248) 29%, rgb(73,51,241) 55%, rgb(113,51,243) 83%, rgb(153,50,245) 100%);
        @include opacity(0.2); }
    i {
        font-size: 25px;
        color: #000; } }
.alert {
    padding: 30px;
    border-radius: 0px;
    border: medium none; }



